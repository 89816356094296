import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  browserPopupRedirectResolver
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { rateLimitHandler } from '../utils/rateLimitHandler';
import { requestQueue } from '../utils/requestQueue';

const AuthContext = createContext();

// Cache duration in milliseconds
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const userCache = useRef(new Map());
  const lastFetch = useRef(new Map());

  const clearCache = useCallback(() => {
    userCache.current.clear();
    lastFetch.current.clear();
  }, []);

  const getCachedData = useCallback((key) => {
    const lastFetchTime = lastFetch.current.get(key);
    if (!lastFetchTime) return null;

    const isExpired = Date.now() - lastFetchTime > CACHE_DURATION;
    if (isExpired) {
      userCache.current.delete(key);
      lastFetch.current.delete(key);
      return null;
    }

    return userCache.current.get(key);
  }, []);

  const setCachedData = useCallback((key, data) => {
    userCache.current.set(key, data);
    lastFetch.current.set(key, Date.now());
  }, []);

  const updateUserData = useCallback(async (firebaseUser) => {
    if (firebaseUser) {
      try {
        // Check cache first
        const cachedUserData = getCachedData(`user:${firebaseUser.uid}`);
        if (cachedUserData) {
          console.log('Using cached user data');
          setUser(cachedUserData);
          return;
        }

        // Get or create user document in Firestore
        const userRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userRef);
        
        let userData = {
          id: firebaseUser.uid,
          email: firebaseUser.email,
          name: firebaseUser.displayName || firebaseUser.email?.split('@')[0] || 'Anonymous User',
          photoURL: firebaseUser.photoURL,
          isAnonymous: firebaseUser.isAnonymous,
          lastUpdated: new Date()
        };

        if (!userDoc.exists()) {
          const newUserData = {
            ...userData,
            createdAt: new Date(),
            photoURL: userData.photoURL || `https://ui-avatars.com/api/?name=${encodeURIComponent(userData.name)}&background=1F2937&color=4ADE80&size=128`
          };
          await setDoc(userRef, newUserData);
          userData = newUserData;
        } else {
          // Only update if data is older than cache duration
          const lastUpdate = userDoc.data().lastUpdated?.toDate();
          if (!lastUpdate || Date.now() - lastUpdate > CACHE_DURATION) {
            await updateDoc(userRef, userData);
          }
        }

        // Cache the user data
        setCachedData(`user:${firebaseUser.uid}`, userData);
        setUser(userData);
      } catch (error) {
        console.error('Error updating user data:', error);
      }
    } else {
      clearCache();
      setUser(null);
    }
  }, [getCachedData, setCachedData, clearCache]);

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      console.log('Auth state changed:', {
        isLoggedIn: !!firebaseUser,
        uid: firebaseUser?.uid,
        email: firebaseUser?.email,
        displayName: firebaseUser?.displayName
      });
      updateUserData(firebaseUser);
      setLoading(false);
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
      clearCache();
    };
  }, [updateUserData, clearCache]);

  const login = async (email, password) => {
    try {
      console.log('Attempting login with email:', email);
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful:', result);
      return result;
    } catch (error) {
      console.error("Error signing in:", error);
      console.error("Error details:", {
        code: error.code,
        message: error.message,
        email: email,
        authInstance: !!auth
      });
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      console.log('Starting Google sign-in process...');
      const provider = new GoogleAuthProvider();
      
      // Add custom parameters
      provider.setCustomParameters({
        prompt: 'select_account',
        login_hint: ''  // Let user choose account
      });

      // Use signInWithPopup directly
      const result = await signInWithPopup(auth, provider);
      console.log('Google sign-in successful:', result.user.email);
      return result;
    } catch (error) {
      console.error("Error signing in with Google:", error.code, error.message);
      // Handle specific error cases
      if (error.code === 'auth/popup-blocked') {
        throw new Error('Please enable popups for this website to sign in with Google');
      } else if (error.code === 'auth/popup-closed-by-user') {
        throw new Error('Sign-in cancelled. Please try again.');
      } else if (error.code === 'auth/cancelled-popup-request') {
        throw new Error('Another sign-in attempt is in progress');
      } else {
        throw new Error('Failed to sign in with Google. Please try again.');
      }
    }
  };

  const logout = async () => {
    try {
      clearCache();
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const refreshUserData = useCallback(() => {
    if (auth.currentUser) {
      // Clear the cache for this user
      userCache.current.delete(`user:${auth.currentUser.uid}`);
      lastFetch.current.delete(`user:${auth.currentUser.uid}`);
      updateUserData(auth.currentUser);
    }
  }, [updateUserData]);

  const value = {
    user,
    loading,
    isAuthenticated: !!user,
    login,
    loginWithGoogle,
    logout,
    refreshUserData
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
