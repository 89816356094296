import React, { useState, useEffect, useRef } from 'react';
import { formatDate } from '../../utils/dateUtils';
import { ChatBubbleLeftIcon, ClipboardIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { CategoryBadge } from './CategoryBadge';
import { Link } from 'react-router-dom';
import { CopyDateButton } from '../buttons/CopyDateButton';
import DeleteConfirmModal from '../modals/DeleteConfirmModal';

export const PromptCard = ({
  id,
  content, 
  createdAt, 
  enhanced,
  author,
  categoryId,
  showActions = true,
  showAuthor = false,
  onEdit,
  onDelete,
  isSelected,
  onSelect,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const contentRef = useRef(null);
  const menuRef = useRef(null);
  const { user } = useAuth();

  // Format the date, with fallback
  const formattedDate = createdAt ? formatDate(createdAt) : 'Recently';

  // Handle copy with animation
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success('Copied to clipboard');
      
      // Reset the copied state after animation
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy to clipboard');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div 
      className={`relative p-6 rounded-xl border dark:border-gray-800 border-gray-200 transition-all duration-200 cursor-pointer
        ${isSelected ? 'dark:bg-gray-800/50 bg-gray-100' : 'dark:bg-gray-900 bg-white'}
        hover:dark:bg-gray-800/30 hover:bg-gray-50`}
      onClick={() => onSelect && onSelect({ id, content, createdAt, enhanced, author, categoryId })}
    >
      {/* Header */}
      <div className="flex items-center gap-3 mb-4">
        {showAuthor && (
          <div className="relative">
            <Link 
              to={`/profile/${author?.id}`}
              className="block cursor-pointer hover:opacity-80 transition-opacity duration-200"
              title={`View ${author?.name || author?.displayName || 'User'}'s profile`}
            >
              <img
                src={author?.photoURL || `https://ui-avatars.com/api/?name=${encodeURIComponent(author?.name || author?.displayName || 'User')}&background=1F2937&color=4ADE80&size=128`}
                alt={author?.name || author?.displayName || 'User'}
                className="w-10 h-10 sm:w-8 sm:h-8 rounded-full object-cover border-2 dark:border-green-500/20 border-green-500/40"
                loading="lazy"
                onError={(e) => {
                  console.log('Image load error:', {
                    originalSrc: e.target.src,
                    author: {
                      name: author?.name,
                      displayName: author?.displayName,
                      photoURL: author?.photoURL,
                      id: author?.id
                    }
                  });
                  e.target.onerror = null;
                  e.target.src = `data:image/svg+xml,${encodeURIComponent(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234ADE80"><circle cx="12" cy="12" r="12" fill="%231F2937"/><text x="12" y="16" text-anchor="middle" fill="%234ADE80" font-size="12">${(author?.name?.[0] || author?.displayName?.[0] || 'U').toUpperCase()}</text></svg>`
                  )}`;
                }}
              />
              <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 dark:border-gray-900 border-white"></div>
            </Link>
          </div>
        )}
        {!showAuthor && (
          <div className="w-10 h-10 rounded-full dark:bg-green-500/20 bg-green-100 flex items-center justify-center">
            <ChatBubbleLeftIcon className="w-5 h-5 dark:text-green-400 text-green-600" />
          </div>
        )}
        <div className="flex-1">
          {showAuthor && (
            <h3 className="text-lg font-semibold dark:text-white text-gray-900 hover:text-green-400 transition-colors duration-200">
              {author?.name || author?.displayName || 'User'}
            </h3>
          )}
          <div className="flex items-center gap-2">
            <p className="text-sm dark:text-gray-400 text-gray-500">{formattedDate}</p>
            <CopyDateButton date={formattedDate} />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="relative bg-black/20 rounded-lg p-4 border border-gray-800 transition-colors duration-200">
        <div className="absolute top-4 right-4">
          <CategoryBadge categoryId={categoryId} />
        </div>
        {isEditing ? (
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            style={{ height: textareaHeight }}
            className="w-full bg-black/10 rounded-lg p-4 dark:text-white text-black font-mono text-base resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your prompt..."
          />
        ) : (
          <pre 
            ref={contentRef}
            className="w-full whitespace-pre-wrap dark:text-white text-black font-mono text-base"
          >
            {content}
          </pre>
        )}
      </div>

      {/* Actions */}
      {showActions && (
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-4">
            {!isEditing && (
              <button 
                className={`flex items-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-200 ${
                  isCopied
                    ? 'dark:bg-green-500/20 bg-green-100 dark:text-green-400 text-green-600'
                    : 'dark:bg-gray-800 bg-black dark:hover:bg-gray-700 hover:bg-black/80 dark:text-green-400 text-green-400'
                }`}
                onClick={handleCopy}
                title={isCopied ? 'Copied!' : 'Copy to clipboard'}
                disabled={isCopied}
              >
                {isCopied ? (
                  <>
                    <CheckIcon className="w-5 h-5" />
                    <span>Copied!</span>
                  </>
                ) : (
                  <>
                    <ClipboardIcon className="w-5 h-5" />
                    <span>Copy</span>
                  </>
                )}
              </button>
            )}
            {user && onEdit && !isEditing && (
              <button 
                onClick={() => {
                  const height = contentRef.current.offsetHeight;
                  setTextareaHeight(`${height}px`);
                  setIsEditing(true);
                  setEditedContent(content);
                }}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg dark:bg-gray-800 bg-black dark:hover:bg-gray-700 hover:bg-black/80 dark:text-blue-400 text-blue-400 transition-colors duration-200"
                title="Edit prompt"
              >
                <PencilIcon className="w-5 h-5" />
                <span>Edit</span>
              </button>
            )}
            {isEditing && (
              <>
                <button 
                  onClick={() => {
                    onEdit(editedContent);
                    setIsEditing(false);
                  }}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-lg dark:bg-blue-500/20 bg-blue-100 dark:text-blue-400 text-blue-600 hover:bg-blue-200 dark:hover:bg-blue-500/30 transition-colors duration-200"
                  title="Save changes"
                >
                  <CheckIcon className="w-5 h-5" />
                  <span>Save</span>
                </button>
                <button 
                  onClick={() => {
                    setIsEditing(false);
                    setEditedContent(content);
                  }}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-lg dark:bg-gray-800 bg-black dark:hover:bg-gray-700 hover:bg-black/80 dark:text-gray-400 text-gray-400 transition-colors duration-200"
                  title="Cancel editing"
                >
                  <span>Cancel</span>
                </button>
              </>
            )}
            {user && onDelete && !isEditing && (
              <button 
                onClick={() => setShowDeleteModal(true)}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg dark:bg-gray-800 bg-black dark:hover:bg-gray-700 hover:bg-black/80 dark:text-red-400 text-red-400 transition-colors duration-200"
                title="Delete prompt"
              >
                <TrashIcon className="w-5 h-5" />
                <span>Delete</span>
              </button>
            )}
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          onDelete();
          setShowDeleteModal(false);
        }}
      />
    </div>
  );
}