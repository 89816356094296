import React from 'react';

function EntertainmentMedia() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Entertainment & Media</h1>
      <p>Welcome to the Entertainment & Media category page.</p>
    </div>
  );
}

export default EntertainmentMedia;
