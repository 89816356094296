import React from 'react';

function SportsRecreation() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Sports & Recreation</h1>
      <p>Welcome to the Sports & Recreation category page.</p>
    </div>
  );
}

export default SportsRecreation;
