import React from 'react';

function ArtDesign() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Art & Design</h1>
      <p>Welcome to the Art & Design category page.</p>
    </div>
  );
}

export default ArtDesign;
