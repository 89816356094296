import axios from 'axios';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs,
  orderBy,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  setDoc
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { requestQueue } from './apiRetry';
import { promptCache, categoryCache, enhancementCache } from './cache';

// Keep the OpenRouter API for prompt enhancement
const openRouterApi = axios.create({
  baseURL: 'https://openrouter.ai/api/v1',
  headers: {
    'Authorization': `Bearer ${import.meta.env.VITE_OPENROUTER_API_KEY}`,
    'HTTP-Referer': window.location.origin,
    'Content-Type': 'application/json'
  }
});

// Custom error class for rate limits
export class RateLimitError extends Error {
  constructor(message, retryAfter = 3600) {
    super(message);
    this.name = 'RateLimitError';
    this.retryAfter = retryAfter;
    this.isRateLimit = true;
  }
}

// Extract retry time from error message
export const extractRetryTime = (message) => {
  if (!message) return 3600; // Default to 1 hour if no message

  const messageStr = message.toLowerCase();
  
  // Handle various rate limit message formats
  if (messageStr.includes('rate limit exceeded')) {
    // Extract time from "try again in about X hours/minutes" format
    const timeMatch = messageStr.match(/try again in (?:about )?(\d+) (hour|minute)s?/i);
    if (timeMatch) {
      const [, time, unit] = timeMatch;
      return unit.toLowerCase() === 'hour' ? time * 3600 : time * 60;
    }
    
    // If no specific time mentioned but rate limit exceeded
    if (messageStr.includes('try again in about an hour') || 
        messageStr.includes('try again in an hour')) {
      return 3600;
    }
  }

  return 3600; // Default fallback
};

// Wrap API calls with rate limit handling
export const wrapWithRateLimitHandling = (apiCall) => {
  return async (...args) => {
    try {
      return await apiCall(...args);
    } catch (error) {
      if (error.response?.status === 429 || 
          (error.message && error.message.toLowerCase().includes('rate limit'))) {
        const retryTime = extractRetryTime(error.message);
        throw new RateLimitError(error.message, retryTime);
      }
      throw error;
    }
  };
};

// Helper to extract retry time from error message
const extractRetryTimeOld = (errorMessage) => {
  const match = errorMessage.match(/try again in (?:about )?(\d+) (?:hour|minute)s?/i);
  if (match) {
    const amount = parseInt(match[1]);
    const isHours = errorMessage.toLowerCase().includes('hour');
    return isHours ? amount * 3600 : amount * 60;
  }
  return 3600; // default to 1 hour if we can't parse the time
};

// Enhance a prompt using AI
export const enhancePrompt = async (prompt) => {
  // Check cache first
  const cacheKey = `enhance:${prompt}`;
  const cachedResult = enhancementCache.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  // If not in cache, queue the request
  const enhanceRequest = async () => {
    const response = await openRouterApi.post('/chat/completions', {
      model: "openai/gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are an expert at improving and optimizing prompts for AI models. Your task is to enhance the given prompt to make it more effective, clear, and detailed."
        },
        {
          role: "user",
          content: `Please enhance this prompt: ${prompt}`
        }
      ]
    });
    const result = response.data.choices[0].message.content;
    enhancementCache.set(cacheKey, result);
    return result;
  };

  return await requestQueue.add(wrapWithRateLimitHandling(enhanceRequest));
};

// Detect prompt category using AI
export const detectPromptCategory = async (prompt) => {
  // Check cache first
  const cacheKey = `category:${prompt}`;
  const cachedResult = categoryCache.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  // If not in cache, queue the request
  const detectRequest = async () => {
    const response = await openRouterApi.post('/chat/completions', {
      model: "openai/gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a prompt categorisation expert. Categorise the given prompt into one of these categories: 
          - creative-writing (for stories, narratives, creative content)
          - code-generation (for programming and development tasks)
          - image-prompts (for image generation or visual descriptions)
          - business (for business strategies, marketing, etc)
          - tech (for technology explanations and concepts)
          
          Respond ONLY with the category ID, nothing else. For example: "image-prompts"`
        },
        {
          role: "user",
          content: prompt
        }
      ]
    });
    
    const category = response.data.choices[0].message.content.trim().toLowerCase();
    const result = ['creative-writing', 'code-generation', 'image-prompts', 'business', 'tech'].includes(category) ? category : 'uncategorized';
    categoryCache.set(cacheKey, result);
    return result;
  };

  return await requestQueue.add(wrapWithRateLimitHandling(detectRequest));
};

// Fallback prompts when API is rate limited
const fallbackPrompts = [
  "Write a story about a character who discovers they can communicate with plants",
  "Create a dialogue between two historical figures from different time periods",
  "Describe a futuristic city where gravity works differently",
  "Write about a mysterious package that arrives with no return address",
  "Compose a scene where someone experiences déjà vu that turns out to be real",
  "Detail the discovery of an ancient artifact in an unexpected place",
  "Write about a day in the life of someone who can see one minute into the future",
  "Create a story about the last library on Earth",
  "Describe a world where dreams are traded as currency",
  "Write about a character who wakes up to find everyone else has vanished",
  "Detail the first contact between humans and a microscopic alien civilization",
  "Compose a scene where time starts moving backwards",
  "Write about a restaurant that serves memories instead of food",
  "Create a story about a person who can taste emotions",
  "Describe the discovery of a door that leads to anywhere you wish"
];

// Generate a random prompt
export const randomisePrompt = async (model = "openai/gpt-3.5-turbo") => {
  const generateRequest = async () => {
    try {
      const response = await openRouterApi.post('/chat/completions', {
        model: model,
        messages: [{
          role: "system",
          content: "You are a creative writing assistant. Generate a unique, engaging writing prompt."
        }],
        temperature: 0.9,
        max_tokens: 100
      });
      return response.data.choices[0].message.content;
    } catch (error) {
      // If rate limited, use fallback prompts
      if (error.response?.status === 429 || error.message?.toLowerCase().includes('rate limit')) {
        console.log('API rate limited, using fallback prompt');
        // Get a random prompt from our fallback list
        const randomIndex = Math.floor(Math.random() * fallbackPrompts.length);
        return fallbackPrompts[randomIndex];
      }
      throw error;
    }
  };
  
  try {
    return await requestQueue.add(wrapWithRateLimitHandling(generateRequest));
  } catch (error) {
    // Final fallback if queue fails
    console.log('Queue failed, using direct fallback');
    const randomIndex = Math.floor(Math.random() * fallbackPrompts.length);
    return fallbackPrompts[randomIndex];
  }
};

// Alias for backward compatibility
export const generateRandomPrompt = randomisePrompt;

// Save a new prompt
export const savePrompt = async (promptData) => {
  try {
    // If no category is specified, detect it automatically
    if (!promptData.categoryId) {
      const detectedCategory = await detectPromptCategory(promptData.content);
      promptData.categoryId = detectedCategory;
    }

    const authorData = {
      id: promptData.userId,
      name: promptData.userName || 'Anonymous',
      avatar: promptData.userAvatar || null
    };

    const timestamp = serverTimestamp();
    const finalPromptData = {
      content: promptData.content.trim(),
      categoryId: promptData.categoryId,
      userId: promptData.userId,
      createdAt: timestamp,
      updatedAt: timestamp,
      likes: 0,
      author: authorData
    };

    const docRef = await addDoc(collection(db, 'prompts'), finalPromptData);
    return { id: docRef.id, ...finalPromptData };
  } catch (error) {
    console.error('Error saving prompt:', error);
    throw error;
  }
};

export const updatePrompt = async (promptId, updatedData) => {
  try {
    if (!promptId) {
      throw new Error('No prompt ID provided for update');
    }
    
    console.log('Starting prompt update:', { promptId, updatedData });
    const promptRef = doc(db, 'prompts', promptId);
    
    await updateDoc(promptRef, {
      ...updatedData,
      updatedAt: serverTimestamp()
    });
    
    console.log('Prompt updated successfully');

    // Add prompt to category folder on the server
    await axios.post('http://localhost:3001/all-categories', {
      promptId,
      categoryId: updatedData.categoryId
    });
    console.log('Prompt added to category folder successfully');

    return {
      id: promptId,
      ...updatedData
    };
  } catch (error) {
    console.error('Error updating prompt:', error);
    throw new Error('Failed to update prompt: ' + error.message);
  }
};

export const getPrompts = async (userId) => {
  try {
    if (!userId) {
      throw new Error('No user ID provided for fetching prompts');
    }
    
    console.log('Fetching prompts for user:', userId);
    const promptsRef = collection(db, 'prompts');
    
    // Create query
    const q = query(
      promptsRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );
    
    // Execute query
    const querySnapshot = await getDocs(q);
    
    // Get user details for the author
    const userDetails = await getUserDetails(userId);
    console.log('Author details:', userDetails);
    
    // Process results
    const prompts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate().toISOString(),
      updatedAt: doc.data().updatedAt?.toDate().toISOString(),
      author: userDetails, // Add author details to each prompt
      categoryId: doc.data().categoryId // Ensure categoryId is passed through
    }));
    
    console.log(`Found ${prompts.length} prompts`);
    return prompts;
  } catch (error) {
    console.error('Error fetching prompts:', error);
    if (error.code === 'failed-precondition' || error.code === 'unimplemented') {
      // Handle missing index
      console.log('Attempting to fetch without ordering...');
      const promptsRef = collection(db, 'prompts');
      const basicQuery = query(
        promptsRef,
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(basicQuery);
      
      // Get user details for the author
      const userDetails = await getUserDetails(userId);
      console.log('Author details:', userDetails);
      
      const prompts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate().toISOString(),
        author: userDetails, // Add author details to each prompt
        categoryId: doc.data().categoryId // Ensure categoryId is passed through
      }));
      // Sort in memory
      prompts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return prompts;
    }
    throw error;
  }
};

export const deletePrompt = async (promptId) => {
  try {
    if (!promptId) {
      throw new Error('No prompt ID provided for deletion');
    }

    console.log('Deleting prompt:', promptId);
    const promptRef = doc(db, 'prompts', promptId);
    await deleteDoc(promptRef);
    
    console.log('Prompt deleted successfully:', promptId);
    return true;
  } catch (error) {
    console.error('Error deleting prompt:', error);
    throw new Error('Failed to delete prompt: ' + error.message);
  }
};

export const updateUserSettings = async (userId, settings) => {
  try {
    if (!userId) {
      throw new Error('No user ID provided for updating settings');
    }

    // Get reference to the user's settings document in the nested structure
    const settingsRef = doc(db, 'users', userId, 'settings', 'preferences');
    
    // Update or create the settings document
    await setDoc(settingsRef, {
      ...settings,
      updatedAt: serverTimestamp()
    }, { merge: true });

    return {
      ...settings
    };
  } catch (error) {
    console.error('Error updating user settings:', error);
    throw error;
  }
};

export const getUserSettings = async (userId) => {
  if (!userId) {
    console.error('getUserSettings called with no userId');
    throw new Error('User ID is required');
  }

  console.log('Fetching settings for user:', userId);
  
  try {
    const userSettingsRef = doc(db, 'users', userId, 'settings', 'preferences');
    console.log('Attempting to fetch document:', userSettingsRef.path);
    
    const settingsDoc = await getDoc(userSettingsRef);
    console.log('Document exists:', settingsDoc.exists());
    
    const existingSettings = settingsDoc.exists() ? settingsDoc.data() : {};

    const defaultSettings = {
      userId,
      theme: 'dark',
      defaultModel: 'openai/gpt-3.5-turbo',
      savePromptHistory: true,
      notificationsEnabled: true,
      language: 'en',
      displayName: '',
      bio: '',
      photoURL: '',
      socialLinks: {
        twitter: '',
        youtube: '',
        instagram: ''
      }
    };

    // Try to get the user's settings document directly
    const settingsDoc2 = doc(db, 'userSettings', userId);
    const docSnapshot = await getDoc(settingsDoc2);

    // If settings don't exist, create them
    if (!docSnapshot.exists()) {
      console.log('No settings found, creating default settings');
      try {
        await setDoc(settingsDoc2, defaultSettings);
        console.log('Created settings document for user:', userId);
        return {
          id: userId,
          ...defaultSettings
        };
      } catch (createError) {
        console.error('Error creating settings document:', createError);
        throw createError;
      }
    }

    // Return existing settings merged with defaults
    const existingSettings2 = docSnapshot.data();
    return {
      id: userId,
      ...defaultSettings,
      ...existingSettings2
    };
  } catch (error) {
    console.error('Error in getUserSettings:', error);
    throw error;
  }
};

export const getUserDetails = async (userId) => {
  try {
    if (!userId) {
      throw new Error('No user ID provided');
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      console.warn(`No user document found for ID: ${userId}`);
      return null;
    }

    const userData = userDoc.data();
    return {
      id: userId,
      name: userData.name || userData.displayName,
      displayName: userData.displayName || userData.name,
      photoURL: userData.photoURL,
      email: userData.email
    };
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};

export const getCategoryDetails = async (categoryId) => {
  try {
    const categoryDoc = await getDoc(doc(db, 'categories', categoryId));
    if (!categoryDoc.exists()) {
      throw new Error('Category not found');
    }
    const categoryData = categoryDoc.data();

    const promptsQuery = query(
      collection(db, 'prompts'),
      where('categoryId', '==', categoryId),
      orderBy('createdAt', 'desc')
    );
    const promptsSnapshot = await getDocs(promptsQuery);
    const prompts = promptsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return { category: categoryData, prompts };
  } catch (error) {
    console.error('Error fetching category details:', error);
    throw error;
  }
};

// Handle Firebase Auth errors
const handleFirebaseError = (error) => {
  console.error('Firebase error:', error);
  
  if (error.code === 'permission-denied') {
    throw new Error('You do not have permission to perform this action');
  }
  
  if (error.message?.includes('Cross-Origin-Opener-Policy')) {
    // This is a non-critical error that doesn't affect functionality
    console.warn('Cross-Origin-Opener-Policy warning:', error);
    return; // Continue execution
  }
  
  throw error;
};

// Get all prompts with optional filters
export const getAllPrompts = async (filters = {}) => {
  try {
    console.log('Getting prompts with filters:', filters);
    
    const promptsRef = collection(db, 'prompts');
    
    // Simplified query that doesn't require composite index
    const promptQuery = query(
      promptsRef,
      where('isPublic', '==', true),
      orderBy('createdAt', 'desc')
    );
    
    try {
      const querySnapshot = await getDocs(promptQuery);
      
      // Filter results in memory if category is specified
      let prompts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString()
      }));

      // Apply category filter in memory
      if (filters.category && filters.category !== 'All Categories') {
        prompts = prompts.filter(prompt => prompt.category === filters.category);
      }
      
      console.log('Found prompts:', prompts.length);
      return prompts;
    } catch (queryError) {
      // Still handle potential index errors for the simplified query
      if (queryError.code === 'failed-precondition' || queryError.message.includes('requires an index')) {
        console.error('Index error:', queryError);
        return []; // Return empty array instead of throwing
      }
      throw queryError;
    }
  } catch (error) {
    console.error('Error fetching prompts:', error);
    throw error;
  }
};