import React from 'react';

function EducationLearning() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Education & Learning</h1>
      <p>Welcome to the Education & Learning category page.</p>
    </div>
  );
}

export default EducationLearning;
