import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import Login from '../../pages/Login';

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [logoUrl, setLogoUrl] = useState('https://firebasestorage.googleapis.com/v0/b/promptdumpio.firebasestorage.app/o/icon-192x192.png?alt=media&token=24a84e3d-149a-4fd5-b47b-a77797f9caff');
  const { isDark, toggleTheme } = useTheme();
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleFeedClick = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      toast((t) => (
        <div className="flex items-center gap-3">
          <span className="text-sm font-medium">Please log in to access the Feed</span>
          <button
            className="px-3 py-1 text-sm text-white bg-green-500 rounded-md hover:bg-green-600"
            onClick={() => {
              toast.dismiss(t.id);
              setShowLoginPopup(true);
            }}
          >
            Log in
          </button>
        </div>
      ), {
        duration: 4000,
        position: 'top-center',
        className: 'bg-gray-900 text-white'
      });
    }
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // No need to fetch URL from Firebase Storage since it's already provided
  }, []);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuOpen) {
        const nav = document.querySelector('nav');
        // Close if click is outside the navigation
        if (nav && !nav.contains(event.target)) {
          setMobileMenuOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [mobileMenuOpen]);

  return (
    <nav className="bg-slate-900 border-b border-slate-800 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center gap-2"
              onClick={() => {
                window.scrollTo(0, 0);
                setMobileMenuOpen(false);
              }}
            >
              <img
                src={logoUrl}
                alt="promptdump.io logo"
                className="w-8 h-8"
              />
              <span className="text-xl font-bold text-white">promptdump.io</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {isAuthenticated ? (
              <Link
                to="/feed"
                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleLinkClick}
              >
                Feed
              </Link>
            ) : null}
            <Link 
              to={isAuthenticated ? "/feed" : "#"} 
              className="text-gray-300 hover:text-white" 
              onClick={(e) => {
                handleLinkClick();
                handleFeedClick(e);
              }}
            >
              Feed
            </Link>
            <Link to="/explore" className="text-gray-300 hover:text-white" onClick={handleLinkClick}>Explore</Link>
            <Link to="/about" className="text-gray-300 hover:text-white" onClick={handleLinkClick}>About</Link>
            
            <button 
              onClick={toggleTheme}
              className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-slate-800"
              aria-label={isDark ? 'Switch to light mode' : 'Switch to dark mode'}
            >
              {isDark ? (
                <SunIcon className="h-5 w-5 text-gray-300" />
              ) : (
                <MoonIcon className="h-5 w-5 text-gray-300" />
              )}
            </button>
            
            {isAuthenticated ? (
              <>
                <Link to="/profile" className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 animate-gradient" onClick={handleLinkClick}>Profile</Link>
                <button onClick={handleLogout} className="btn-primary">
                  Logout
                </button>
              </>
            ) : (
              <Link to="/login" className="btn-primary" onClick={handleLinkClick}>
                Sign In
              </Link>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center space-x-4">
            <button 
              onClick={toggleTheme}
              className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-slate-800"
              aria-label={isDark ? 'Switch to light mode' : 'Switch to dark mode'}
            >
              {isDark ? (
                <SunIcon className="h-5 w-5 text-gray-300" />
              ) : (
                <MoonIcon className="h-5 w-5 text-gray-300" />
              )}
            </button>

            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-slate-800"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="block h-6 w-6 text-gray-300" />
              ) : (
                <Bars3Icon className="block h-6 w-6 text-gray-300" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${mobileMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t border-slate-800 bg-slate-900/50 backdrop-blur-lg">
          <Link 
            to={isAuthenticated ? "/feed" : "#"}
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-slate-800" 
            onClick={(e) => {
              handleLinkClick();
              handleFeedClick(e);
              setMobileMenuOpen(false);
            }}
          >
            Feed
          </Link>
          <Link to="/explore" className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-slate-800" onClick={handleLinkClick}>
            Explore
          </Link>
          <Link to="/about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-slate-800" onClick={handleLinkClick}>
            About
          </Link>
          {isAuthenticated ? (
            <>
              <Link to="/profile" className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-slate-800" onClick={handleLinkClick}>
                Profile
              </Link>
              <button onClick={() => { handleLogout(); setMobileMenuOpen(false); }} className="w-full mt-4 btn-primary">
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="w-full mt-4 btn-primary" onClick={handleLinkClick}>
              Sign In
            </Link>
          )}
        </div>
      </div>

      {/* Login Popup */}
      {showLoginPopup && (
        <Login isPopup={true} onClose={() => setShowLoginPopup(false)} />
      )}
    </nav>
  );
}

export default Navbar;