import React from 'react';
import { Link } from 'react-router-dom';
import { SparklesIcon, ShareIcon, BeakerIcon } from '@heroicons/react/24/outline';
import PromptForm from '../components/PromptForm';

function Home() {
  return (
    <div className="max-w-7xl mx-auto">
      <div className="mb-12">
        <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-500/10 text-green-400 text-sm font-medium mb-6">
          <SparklesIcon className="w-4 h-4 mr-2" />
          Now with AI-powered prompt suggestions
        </div>
        
        <h1 className="text-5xl font-bold mb-4">
          <span className="text-white">Dump and Discover</span>
          <br />
          <span className="text-gradient">AI Prompts</span>
        </h1>
        
        <p className="text-slate-400 text-xl mb-8">
          Got prompts? Ditch the notepad and dump your prompts to organise, 
          AI enhance and share powerful AI prompts, code and idea starters.
        </p>

        <Link to="/signup" className="btn-primary text-base px-6 py-3">
          <SparklesIcon className="w-5 h-5 mr-2" />
          Get Started
        </Link>
      </div>

      <PromptForm />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 mb-16">
        <div className="glass-card" onClick={() => window.location.href = '/profile'}>
          <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-green-500/10 text-green-400 mb-6">
            <BeakerIcon className="w-6 h-6" />
          </div>
          <h3 className="text-xl font-semibold mb-3">Organise Prompts</h3>
          <p className="text-slate-400 leading-relaxed">
            Keep your prompts organised with categories, tags, and smart collections.
          </p>
        </div>

        <div className="glass-card" onClick={() => window.location.href = '/explore'}>
          <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-green-500/10 text-green-400 mb-6">
            <ShareIcon className="w-6 h-6" />
          </div>
          <h3 className="text-xl font-semibold mb-3">Easy Sharing</h3>
          <p className="text-slate-400 leading-relaxed">
            Share your prompts with the community and get valuable feedback.
          </p>
        </div>

        <div className="glass-card" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-green-500/10 text-green-400 mb-6">
            <SparklesIcon className="w-6 h-6" />
          </div>
          <h3 className="text-xl font-semibold mb-3">AI Suggestions</h3>
          <p className="text-slate-400 leading-relaxed">
            Get intelligent prompt suggestions based on your goals and preferences.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;