import React from 'react';

function SocialSciencesHumanities() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Social Sciences & Humanities</h1>
      <p>Welcome to the Social Sciences & Humanities category page.</p>
    </div>
  );
}

export default SocialSciencesHumanities;
