import React, { useState } from 'react';
import { ClipboardIcon, CheckIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

function TradingStrategies() {
  const [isCopied, setIsCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [date, setDate] = useState('December 4, 2024');
  const [editedDate, setEditedDate] = useState(date);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(date);
      setIsCopied(true);
      toast.success('Date copied to clipboard!');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy date');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedDate(date);
  };

  const handleSave = () => {
    setDate(editedDate);
    setIsEditing(false);
    toast.success('Date updated successfully!');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedDate(date);
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this date?')) {
      setDate('');
      toast.success('Date deleted successfully!');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Trading Strategies</h1>
      <div className="flex items-center gap-2">
        {isEditing ? (
          <>
            <input
              type="text"
              value={editedDate}
              onChange={(e) => setEditedDate(e.target.value)}
              className="text-sm dark:bg-gray-800 dark:text-white border dark:border-gray-700 rounded-lg px-2 py-1"
            />
            <button
              onClick={handleSave}
              className="p-1 rounded-lg hover:bg-green-700/50 transition-colors duration-200"
              title="Save"
            >
              <CheckIcon className="h-4 w-4 text-green-400" />
            </button>
            <button
              onClick={handleCancel}
              className="p-1 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
              title="Cancel"
            >
              <TrashIcon className="h-4 w-4 text-red-400" />
            </button>
          </>
        ) : (
          <>
            <p className="text-sm dark:text-gray-400 text-gray-500">{date}</p>
            <button 
              onClick={handleCopy}
              className="inline-flex items-center gap-1 p-1 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
              title="Copy date"
            >
              {isCopied ? (
                <CheckIcon className="h-4 w-4 text-green-400" />
              ) : (
                <ClipboardIcon className="h-4 w-4 text-gray-400 hover:text-green-400" />
              )}
            </button>
            <button 
              onClick={handleEdit}
              className="p-1 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
              title="Edit date"
            >
              <PencilIcon className="h-4 w-4 text-blue-400 hover:text-blue-300" />
            </button>
            <button 
              onClick={handleDelete}
              className="p-1 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
              title="Delete date"
            >
              <TrashIcon className="h-4 w-4 text-red-400 hover:text-red-300" />
            </button>
          </>
        )}
      </div>
      <p>Welcome to the Trading Strategies category page.</p>
    </div>
  );
}

export default TradingStrategies;
