import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  HeartIcon,
  ArrowPathIcon,
  BookmarkIcon,
  ClipboardIcon,
  TrashIcon,
  PencilIcon,
  XMarkIcon,
  CheckIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/24/outline';
import { CategoryBadge } from './prompt/CategoryBadge';
import toast from 'react-hot-toast';

const PromptCard = ({
  id,
  content,
  categoryId,
  tags = [],
  difficulty = 'beginner',
  length = 'medium',
  likeCount = 0,
  usageCount = 0,
  saveCount = 0,
  createdAt,
  authorId,
  onEdit,
  onDelete
}) => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [isCopied, setIsCopied] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = React.useRef();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success('Copied to clipboard!');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy to clipboard');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedContent(content);
  };

  const handleSave = async () => {
    try {
      await onEdit?.(id, editedContent);
      setIsEditing(false);
      toast.success('Prompt updated successfully');
    } catch (error) {
      toast.error('Failed to update prompt');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedContent(content);
  };

  const handleDelete = async () => {
    try {
      await onDelete?.(id);
      toast.success('Prompt deleted successfully');
    } catch (error) {
      toast.error('Failed to delete prompt');
    }
    setShowDeleteConfirm(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="bg-gray-900 border border-gray-700 rounded-xl p-6 space-y-4">
      {/* Content */}
      <div className="relative bg-gray-950 rounded-lg p-4 border border-gray-800 transition-colors duration-200">
        <div className="flex justify-between items-start mb-3">
          <div className="flex flex-wrap gap-2">
            {tags.map(tag => (
              <span 
                key={tag} 
                className="px-2 py-1 text-xs font-medium rounded-full bg-green-500/10 text-green-400 border border-green-500/20"
              >
                #{tag}
              </span>
            ))}
            {categoryId && <CategoryBadge categoryId={categoryId} />}
          </div>
        </div>
        
        {isEditing ? (
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="w-full min-h-[120px] bg-gray-900 rounded-lg p-4 text-white font-mono text-sm resize-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
            placeholder="Enter your prompt..."
          />
        ) : (
          <pre className="w-full min-h-[120px] whitespace-pre-wrap text-white font-mono text-sm">
            {content}
          </pre>
        )}
      </div>

      {/* Actions */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button 
            onClick={handleCopy}
            className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-gray-700/50 hover:bg-gray-700 text-green-400 transition-colors duration-200"
          >
            {isCopied ? (
              <>
                <CheckIcon className="h-4 w-4" />
                <span>Copied!</span>
              </>
            ) : (
              <>
                <ClipboardIcon className="h-4 w-4" />
                <span>Copy</span>
              </>
            )}
          </button>
          
          <div className="flex items-center gap-4 text-sm text-gray-400">
            <div className="flex items-center gap-1">
              <HeartIcon className="h-4 w-4" />
              <span>{Number.isFinite(likeCount) ? likeCount : 0}</span>
            </div>
            <div className="flex items-center gap-1">
              <ArrowPathIcon className="h-4 w-4" />
              <span>{Number.isFinite(usageCount) ? usageCount : 0}</span>
            </div>
            <div className="flex items-center gap-1">
              <BookmarkIcon className="h-4 w-4" />
              <span>{Number.isFinite(saveCount) ? saveCount : 0}</span>
            </div>
          </div>
        </div>

        {user && user.id === authorId && (
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" />
            </button>
            
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 border border-gray-700 rounded-xl shadow-lg z-10">
                <div className="py-1">
                  <button
                    onClick={() => {
                      handleEdit();
                      setIsMenuOpen(false);
                    }}
                    className="flex items-center gap-2 w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                  >
                    <PencilIcon className="h-4 w-4" />
                    Edit Prompt
                  </button>
                  <button
                    onClick={() => {
                      setShowDeleteConfirm(true);
                      setIsMenuOpen(false);
                    }}
                    className="flex items-center gap-2 w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-700"
                  >
                    <TrashIcon className="h-4 w-4" />
                    Delete Prompt
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Edit Actions */}
      {isEditing && (
        <div className="flex justify-end gap-2">
          <button
            onClick={handleCancel}
            className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-white transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-lg hover:bg-green-600 transition-colors duration-200"
          >
            Save Changes
          </button>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-800 border border-gray-700 rounded-xl p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-medium text-white mb-2">Delete Prompt</h3>
            <p className="text-gray-400 mb-4">Are you sure you want to delete this prompt? This action cannot be undone.</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-white transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors duration-200"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptCard;
