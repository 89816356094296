import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { 
  MicrophoneIcon, 
  StopIcon, 
  ArrowPathIcon,
  SparklesIcon,
  BookmarkIcon as BookmarkOutline
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { categorizePrompt } from '../utils/promptCategorizer';
import { enhancePrompt, generateRandomPrompt } from '../utils/api';
import { savePrompt } from '../utils/api';

const DiceIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    fill="none" 
    viewBox="0 0 24 24" 
    strokeWidth={1.5} 
    stroke="currentColor" 
    className={className}
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" 
    />
  </svg>
);

function PromptForm() {
  const [prompt, setPrompt] = useState('');
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [enhancedPrompt, setEnhancedPrompt] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [selectedModel, setSelectedModel] = useState('openai/gpt-3.5-turbo');
  const recognitionRef = useRef(null);
  const { isAuthenticated, user } = useAuth();

  // Initialize speech recognition
  useEffect(() => {
    if (typeof window !== 'undefined' && 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      
      recognition.continuous = true;
      recognition.interimResults = true;
      
      recognition.onresult = (event) => {
        let finalTranscript = '';
        
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          }
        }
        
        if (finalTranscript) {
          setPrompt(prev => prev + ' ' + finalTranscript);
        }
      };
      
      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecording(false);
        toast.error('Speech recognition failed. Please try again.');
      };
      
      recognitionRef.current = recognition;
    }
    
    return () => {
      if (recognitionRef.current) {
        try {
          recognitionRef.current.stop();
        } catch (error) {
          console.error('Error stopping recognition:', error);
        }
      }
    };
  }, []);

  const toggleRecording = () => {
    if (!recognitionRef.current) {
      toast.error('Speech recognition is not supported in your browser');
      return;
    }

    if (isRecording) {
      recognitionRef.current.stop();
      setIsRecording(false);
    } else {
      try {
        recognitionRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error('Error starting recognition:', error);
        toast.error('Failed to start recording. Please try again.');
      }
    }
  };

  const handleEnhance = async () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt to enhance');
      return;
    }

    setIsEnhancing(true);
    try {
      const enhancedPrompt = await enhancePrompt(prompt);
      setPrompt(enhancedPrompt);
      setEnhancedPrompt(enhancedPrompt);
      toast.success('Prompt enhanced!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleSave = async () => {
    if (!isAuthenticated) {
      toast.error('Please sign in to save prompts');
      return;
    }

    if (!prompt.trim()) {
      toast.error('Please enter a prompt to save');
      return;
    }

    if (!user?.id) {
      toast.error('User ID not available. Please try logging in again.');
      return;
    }

    setIsSaving(true);
    try {
      console.log('Starting prompt save process...');
      
      // Categorize the prompt
      console.log('Categorizing prompt...');
      const categorization = await categorizePrompt(prompt);
      console.log('Categorization result:', categorization);
      
      // Create the prompt object
      const promptData = {
        content: prompt,
        userId: user.id,
        author: {
          id: user.id,
          displayName: user.name || user.displayName,
          photoURL: user.photoURL,
          email: user.email
        },
        category: categorization.category || 'uncategorized',
        subcategory: categorization.subcategory || 'general',
        tags: categorization.tags || [],
        purpose: categorization.purpose || '',
        createdAt: new Date().toISOString(),
        model: selectedModel
      };
      console.log('Prompt data to save:', promptData);

      // Save using the API utility function
      const savedPrompt = await savePrompt(promptData);
      console.log('Prompt saved successfully:', savedPrompt);

      toast.success('Prompt saved successfully!');
      setPrompt(''); // Clear the form after successful save
    } catch (error) {
      console.error('Detailed save error:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Failed to save prompt';
      toast.error(`Error: ${errorMessage}`);
      
      if (error.message.includes('Network Error')) {
        toast.error('Cannot connect to server. Please make sure the server is running.');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleRandomPrompt = async () => {
    setIsGenerating(true);
    try {
      const randomPrompt = await generateRandomPrompt(selectedModel);
      if (!randomPrompt) {
        throw new Error('Failed to generate prompt');
      }
      setPrompt(randomPrompt);
      toast.success('Random prompt generated!');
    } catch (error) {
      console.error('Error generating random prompt:', error);
      if (error.message?.toLowerCase().includes('rate limit')) {
        toast.error('Rate limit reached. Using fallback prompt instead.');
      } else {
        toast.error('Failed to generate random prompt');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="glass-card">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold text-white">Dump your prompt</h2>
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-green-500/10 text-green-400 text-sm">
          <SparklesIcon className="w-4 h-4 mr-1" />
          AI Powered
        </div>
      </div>
      
      <div className="space-y-4">
        {/* Controls - Centered above textarea */}
        <div className="flex items-center justify-center gap-8 mb-4">
          {/* Microphone Button */}
          <button
            onClick={toggleRecording}
            className={`p-3 rounded-full transition-all duration-200 ${
              isRecording
                ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30'
                : 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
            }`}
            title={isRecording ? 'Stop Recording' : 'Start Recording'}
          >
            {isRecording ? <StopIcon className="h-6 w-6" /> : <MicrophoneIcon className="h-6 w-6" />}
          </button>

          {/* Random Prompt Button */}
          <button
            onClick={handleRandomPrompt}
            disabled={isGenerating}
            className={`p-3 rounded-full bg-green-500/20 text-green-400 hover:bg-green-500/30 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed ${isGenerating ? 'animate-spin-slow' : ''}`}
            title={isGenerating ? 'Generating...' : 'Generate Random Prompt'}
          >
            <DiceIcon className={`h-6 w-6 ${isGenerating ? 'animate-bounce' : ''}`} />
          </button>
        </div>

        {/* Model Selection */}
        <div className="flex flex-col space-y-2 max-w-xl mx-auto mb-4">
          <label htmlFor="defaultModel" className="text-sm font-medium text-gray-400">AI Model</label>
          <select
            id="defaultModel"
            name="defaultModel"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            className="form-select bg-gray-700 border-gray-600 text-white rounded-lg"
          >
            <option value="openai/gpt-3.5-turbo">GPT-3.5 Turbo</option>
            <option value="openai/gpt-4">GPT-4</option>
            <option value="anthropic/claude-2">Claude 2</option>
          </select>
        </div>

        {/* Prompt Input */}
        <div className="relative">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
            className="w-full h-32 p-4 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent resize-none"
          />
        </div>
        
        {/* Added spacing before buttons */}
        <div className="h-4"></div>
        
        <div className="flex gap-4">
          <button
            onClick={handleEnhance}
            disabled={isEnhancing}
            className="btn-enhance flex-1"
          >
            {isEnhancing ? (
              <span className="flex items-center justify-center">
                <ArrowPathIcon className="w-5 h-5 mr-2 animate-spin" />
                Enhancing...
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <SparklesIcon className="w-5 h-5 mr-2" />
                Enhance Prompt
              </span>
            )}
          </button>
          
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="btn-primary flex-1"
          >
            {isSaving ? (
              <span className="flex items-center justify-center">
                <ArrowPathIcon className="w-5 h-5 mr-2 animate-spin" />
                Saving...
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <BookmarkOutline className="w-5 h-5 mr-2" />
                Save Prompt
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PromptForm;