import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMonitor, FiUsers, FiZap, FiShield } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

function GetStarted() {
  const { loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await loginWithGoogle();
      toast.success('Signed in with Google successfully!');
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Google sign-in error:', error);
      toast.error(error.message || 'Failed to sign in with Google');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Hero Section */}
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center max-w-4xl mx-auto">
          <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 mb-8">
            Build Robust AI Applications
          </h1>
          <p className="text-2xl text-gray-300 mb-12">
            Streamline the development of your LLM applications.
            Experiment, evaluate, and monitor AI applications faster and
            easier than ever before.
          </p>
          
          {/* Authentication Section */}
          <div className="max-w-md mx-auto bg-gray-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-8 shadow-xl border border-gray-700">
            <h2 className="text-2xl font-bold text-white mb-6">Get Started Today</h2>
            
            {/* Google Sign In */}
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center gap-3 px-6 py-3 mb-4 bg-white hover:bg-gray-50 text-gray-800 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform hover:scale-105 transition-all shadow-md"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="#4285F4"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                />
                <path
                  fill="#34A853"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                />
                <path
                  fill="#FBBC05"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                />
                <path
                  fill="#EA4335"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                />
              </svg>
              Continue with Google
            </button>

            {/* Separator */}
            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-600"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text-gray-400 bg-gray-800">Or</span>
              </div>
            </div>

            {/* Email Sign Up */}
            <Link
              to="/signup"
              className="w-full flex items-center justify-center px-6 py-3 text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform hover:scale-105 transition-all shadow-md"
            >
              Sign up with Email
            </Link>
          </div>
        </div>

        {/* Features Grid */}
        <div className="mt-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
          <div className="bg-gray-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-10 transform hover:scale-105 transition-all border border-gray-700">
            <div className="flex items-center mb-6">
              <FiMonitor className="h-10 w-10 text-blue-400" />
              <h2 className="text-2xl font-semibold text-white ml-4">
                Monitor & Analyze
              </h2>
            </div>
            <p className="text-gray-300 text-lg leading-relaxed">
              Track performance metrics, usage patterns, and system health
              in real-time with our comprehensive monitoring dashboard.
            </p>
          </div>

          <div className="bg-gray-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-10 transform hover:scale-105 transition-all border border-gray-700">
            <div className="flex items-center mb-6">
              <FiUsers className="h-10 w-10 text-purple-400" />
              <h2 className="text-2xl font-semibold text-white ml-4">
                Collaborate Seamlessly
              </h2>
            </div>
            <p className="text-gray-300 text-lg leading-relaxed">
              Work together effectively with built-in collaboration tools,
              version control, and shared workspaces.
            </p>
          </div>

          <div className="bg-gray-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-10 transform hover:scale-105 transition-all border border-gray-700">
            <div className="flex items-center mb-6">
              <FiZap className="h-10 w-10 text-yellow-400" />
              <h2 className="text-2xl font-semibold text-white ml-4">
                Rapid Development
              </h2>
            </div>
            <p className="text-gray-300 text-lg leading-relaxed">
              Accelerate your development process with our intuitive
              interface and powerful development tools.
            </p>
          </div>

          <div className="bg-gray-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-10 transform hover:scale-105 transition-all border border-gray-700">
            <div className="flex items-center mb-6">
              <FiShield className="h-10 w-10 text-green-400" />
              <h2 className="text-2xl font-semibold text-white ml-4">
                Enterprise Security
              </h2>
            </div>
            <p className="text-gray-300 text-lg leading-relaxed">
              Rest easy with enterprise-grade security features and
              compliance standards built into every aspect of the platform.
            </p>
          </div>
        </div>

        {/* Platform Preview */}
        <div className="mt-32">
          <div className="relative rounded-xl overflow-hidden shadow-2xl">
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent z-10"></div>
            <img
              src="/images/dashboard-preview.webp"
              alt="Platform Dashboard"
              className="w-full object-cover"
            />
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-32 text-center max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold text-white mb-8">
            Ready to Transform Your AI Development?
          </h2>
          <div className="flex flex-col sm:flex-row justify-center gap-6">
            <Link
              to="/signup"
              className="px-12 py-4 text-lg font-medium rounded-lg text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 transform hover:scale-105 transition-all shadow-md"
            >
              Get Started Free
            </Link>
            <Link
              to="/contact"
              className="px-12 py-4 text-lg font-medium rounded-lg text-white border-2 border-gray-300 hover:bg-gray-700 transform hover:scale-105 transition-all"
            >
              Contact Sales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
