import React from 'react';

export const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center min-h-[200px]">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500 dark:border-green-500 light:border-green-600"></div>
    </div>
  );
};

// Also provide a default export for backward compatibility
export default LoadingSpinner;
