import React from 'react';

function TravelAdventure() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Travel & Adventure</h1>
      <p>Welcome to the Travel & Adventure category page.</p>
    </div>
  );
}

export default TravelAdventure;
