import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/navigation/Navbar.jsx';
import Footer from './components/Footer.jsx';

function App() {
  const location = useLocation();
  const isGetStarted = location.pathname === '/get-started';
  const showFooter = location.pathname !== '/login' && location.pathname !== '/register';

  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <Toaster position="top-center" />
      {!isGetStarted && <Navbar />}
      <main className="flex-grow">
        <Outlet />
      </main>
      {showFooter && !isGetStarted && <Footer />}
    </div>
  );
}

export default App;