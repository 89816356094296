import React, { useState } from 'react';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

export const CopyDateButton = ({ date }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(date);
      setIsCopied(true);
      toast.success('Date copied to clipboard!');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy date');
    }
  };

  return (
    <button 
      onClick={handleCopy}
      className="inline-flex items-center gap-1 ml-2 p-1 rounded-lg hover:bg-gray-700/50 transition-colors duration-200"
      title="Copy date"
    >
      {isCopied ? (
        <CheckIcon className="h-4 w-4 text-green-400" />
      ) : (
        <ClipboardIcon className="h-4 w-4 text-gray-400 hover:text-green-400" />
      )}
    </button>
  );
};
