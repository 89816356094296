export const PROMPT_CATEGORIES = {
  'creative-writing': {
    id: 'creative-writing',
    label: 'Creative Writing'
  },
  'code-generation': {
    id: 'code-generation',
    label: 'Code Generation'
  },
  'image-prompts': {
    id: 'image-prompts',
    label: 'Image Prompts'
  },
  'business': {
    id: 'business',
    label: 'Business'
  },
  'tech': {
    id: 'tech',
    label: 'Technology'
  },
  'uncategorized': {
    id: 'uncategorized',
    label: 'Uncategorized'
  }
};

export const getCategoryById = (categoryId) => {
  return PROMPT_CATEGORIES[categoryId] || PROMPT_CATEGORIES['uncategorized'];
};
