// Exponential backoff retry utility
const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export class RateLimitError extends Error {
  constructor(message, retryAfter) {
    super(message);
    this.name = 'RateLimitError';
    this.retryAfter = retryAfter;
  }
}

export async function withRetry(operation, options = {}) {
  const {
    maxRetries = 3,
    initialDelay = 1000,
    maxDelay = 60000,
    factor = 2,
  } = options;

  let lastError;
  let delay = initialDelay;

  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;

      // Check if it's a rate limit error
      if (error.response?.status === 429 || error.message?.includes('rate limit')) {
        const retryAfter = error.response?.headers?.['retry-after'] || 60;
        const waitTime = Math.min(retryAfter * 1000, maxDelay);
        
        console.log(`Rate limited. Waiting ${waitTime/1000} seconds before retry...`);
        await wait(waitTime);
        continue;
      }

      // For other errors, use exponential backoff
      if (attempt === maxRetries) {
        throw lastError;
      }

      delay = Math.min(delay * factor, maxDelay);
      console.log(`Attempt ${attempt + 1} failed. Retrying in ${delay/1000} seconds...`);
      await wait(delay);
    }
  }

  throw lastError;
}

// Queue for rate limiting
export class RequestQueue {
  constructor(requestsPerMinute = 10) { 
    this.queue = [];
    this.processing = false;
    this.requestsPerMinute = requestsPerMinute;
    this.requestTimes = [];
    this.errorCount = 0;
    this.lastErrorTime = null;
  }

  async add(operation) {
    return new Promise((resolve, reject) => {
      this.queue.push({ operation, resolve, reject });
      if (!this.processing) {
        this.process();
      }
    });
  }

  async process() {
    if (this.queue.length === 0) {
      this.processing = false;
      return;
    }

    this.processing = true;
    const now = Date.now();
    
    // Remove request timestamps older than 1 minute
    this.requestTimes = this.requestTimes.filter(time => now - time < 60000);

    // If we've hit rate limits recently, be more conservative
    if (this.errorCount > 0) {
      const timeSinceLastError = now - (this.lastErrorTime || 0);
      if (timeSinceLastError < 300000) { // 5 minutes
        await wait(5000); // Add extra delay between requests
      } else {
        this.errorCount = 0; // Reset error count after 5 minutes
      }
    }

    // If we've hit the rate limit, wait until we can make another request
    if (this.requestTimes.length >= this.requestsPerMinute) {
      const oldestRequest = this.requestTimes[0];
      const waitTime = 60000 - (now - oldestRequest) + 1000; // Added 1s buffer
      if (waitTime > 0) {
        await wait(waitTime);
      }
    }

    const { operation, resolve, reject } = this.queue.shift();

    try {
      // Add the current request timestamp
      this.requestTimes.push(Date.now());
      
      // Execute the operation with retries
      const result = await withRetry(operation, {
        maxRetries: 3,
        initialDelay: 2000,
        maxDelay: 60000,
        factor: 2,
      });
      
      resolve(result);
    } catch (error) {
      if (error.message?.includes('rate limit')) {
        this.errorCount++;
        this.lastErrorTime = Date.now();
        // Put the failed operation back at the start of the queue
        this.queue.unshift({ operation, resolve, reject });
        await wait(10000); // Wait 10s before retrying
      } else {
        reject(error);
      }
    }

    // Process next request
    this.process();
  }
}

export const requestQueue = new RequestQueue(10);
