// Rate limit handler utility with caching and exponential backoff
class RateLimitHandler {
  constructor(options = {}) {
    this.maxRetries = options.maxRetries || 3;
    this.baseDelay = options.baseDelay || 1000;
    this.maxDelay = options.maxDelay || 3600000; // 1 hour
    this.retryCount = 0;
    this.lastRequestTime = 0;
    this.rateLimitCache = new Map(); // Cache to track rate limits per operation
    this.minRequestInterval = 2000; // Minimum time between requests (2 seconds)
    this.retryDelays = [1000, 2000, 5000, 10000, 30000]; // Increasing delays in milliseconds
  }

  isRateLimitError(error) {
    if (!error) return false;
    
    const errorMessage = error.message?.toLowerCase() || '';
    const errorCode = error.code?.toLowerCase() || '';
    
    // Check for various rate limit indicators
    return (
      errorMessage.includes('rate limit') ||
      errorMessage.includes('resource_exhausted') ||
      errorCode.includes('quota') ||
      errorCode.includes('resource-exhausted')
    );
  }

  extractRetryTime(error) {
    if (!error?.message) return 3600; // Default to 1 hour

    const message = error.message.toLowerCase();
    
    // Extract time from various formats
    const hourMatch = message.match(/try again in about (\d+) hour/);
    if (hourMatch) return parseInt(hourMatch[1]) * 3600;
    
    const minuteMatch = message.match(/try again in about (\d+) minute/);
    if (minuteMatch) return parseInt(minuteMatch[1]) * 60;
    
    const secondMatch = message.match(/try again in about (\d+) second/);
    if (secondMatch) return parseInt(secondMatch[1]);
    
    return 3600; // Default to 1 hour if no match
  }

  getRateLimitKey(operation) {
    return operation.toString().slice(0, 100); // Use first 100 chars of function as key
  }

  async shouldThrottle(key) {
    const now = Date.now();
    const lastRequest = this.rateLimitCache.get(key);
    
    if (lastRequest) {
      const timeElapsed = now - lastRequest.timestamp;
      if (timeElapsed < lastRequest.cooldown * 1000) {
        const remainingTime = Math.ceil((lastRequest.cooldown * 1000 - timeElapsed) / 1000);
        throw new Error(`Please wait ${remainingTime} seconds before trying again.`);
      }
    }
    
    // Ensure minimum interval between requests
    const timeSinceLastRequest = now - this.lastRequestTime;
    if (timeSinceLastRequest < this.minRequestInterval) {
      await new Promise(resolve => setTimeout(resolve, this.minRequestInterval - timeSinceLastRequest));
    }
    
    this.lastRequestTime = now;
    return false;
  }

  updateRateLimit(key, cooldown) {
    this.rateLimitCache.set(key, {
      timestamp: Date.now(),
      cooldown: cooldown
    });
  }

  async executeWithRetry(operation, attempt = 0) {
    try {
      await this.shouldThrottle(this.getRateLimitKey(operation));
      const result = await operation();
      return result;
    } catch (error) {
      if (this.isRateLimitError(error)) {
        const retryTime = this.extractRetryTime(error);
        this.updateRateLimit(this.getRateLimitKey(operation), retryTime);
        
        // Format error message based on retry time
        const timeUnit = retryTime >= 3600 ? 'hour' : retryTime >= 60 ? 'minute' : 'second';
        const timeValue = retryTime >= 3600 ? Math.ceil(retryTime / 3600) : 
                         retryTime >= 60 ? Math.ceil(retryTime / 60) : 
                         retryTime;
        
        throw new Error(
          `Rate limit exceeded. Please try again in ${timeValue} ${timeUnit}${timeValue !== 1 ? 's' : ''}.`
        );
      } else if (
        (error.message?.includes('rate limit') || error.message?.includes('resource_exhausted')) &&
        attempt < this.maxRetries
      ) {
        const delay = this.retryDelays[attempt] || this.retryDelays[this.retryDelays.length - 1];
        console.log(`Rate limit hit. Retrying in ${delay}ms... (Attempt ${attempt + 1}/${this.maxRetries})`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.executeWithRetry(operation, attempt + 1);
      }
      throw error;
    }
  }

  getRetryAfterHeader(error) {
    try {
      return parseInt(error.response?.headers?.['retry-after']) * 1000 || 3600000; // Default to 1 hour
    } catch {
      return 3600000; // 1 hour in milliseconds
    }
  }

  clearRateLimit(key) {
    this.rateLimitCache.delete(key);
  }
}

export const rateLimitHandler = new RateLimitHandler();
export default RateLimitHandler;
