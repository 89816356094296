import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CommandLineIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import Login from '../pages/Login';

function XIcon() {
  return (
    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </svg>
  );
}

const footerSections = [
  {
    title: 'Home',
    links: [
      { name: 'Dump', to: '/' },
      { name: 'Feed', to: '/feed', requiresAuth: true },
      { name: 'Explore', to: '/explore' },
      { name: 'About', to: '/about' },
    ],
  },
  {
    title: 'Support',
    links: [
      { name: 'Help Center', to: '/helpcentre' },
      { name: 'Contact', to: '/contact' },
      { name: 'Feature Request', to: '/feature-request' },
      { name: 'FAQ', to: '/faq', component: Link },
    ],
  },
  {
    title: 'Legal',
    links: [
      { name: 'Terms of Service', to: '/terms' },
      { name: 'Privacy Policy', to: '/privacy' },
      { name: 'Cookie Policy', to: '/cookies' },
    ],
  },
];

const socialLinks = [
  { name: 'Twitter', href: 'https://x.com/promptdumpio' },
  { name: 'GitHub', href: 'https://github.com/promptdump' },
  { name: 'Discord', href: 'https://discord.com/invite/promptdump' },
  { name: 'LinkedIn', href: 'https://www.linkedin.com/in/promtdump' },
];

function Footer() {
  const currentYear = new Date().getFullYear();
  const { isAuthenticated } = useAuth();
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handleLinkClick = (e, requiresAuth) => {
    window.scrollTo(0, 0);
    if (requiresAuth && !isAuthenticated) {
      e.preventDefault();
      toast((t) => (
        <div className="flex items-center gap-3">
          <span className="text-sm font-medium">Please log in to access the Feed</span>
          <button
            className="px-3 py-1 text-sm text-white bg-green-500 rounded-md hover:bg-green-600"
            onClick={() => {
              toast.dismiss(t.id);
              setShowLoginPopup(true);
            }}
          >
            Log in
          </button>
        </div>
      ), {
        duration: 4000,
        position: 'top-center',
        className: 'bg-gray-900 text-white'
      });
    }
  };

  return (
    <footer className="mt-auto bg-gray-800 text-gray-300">
      <div className="container py-12 lg:py-16">
        {/* Main footer content */}
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
          {footerSections.map((section) => (
            <div key={section.title}>
              <h3 className="text-sm font-semibold tracking-wider uppercase text-white">
                {section.title}
              </h3>
              <ul className="mt-4 space-y-2">
                {section.links.map((link) => (
                  <li key={link.name}>
                    {link.component ? (
                      <link.component
                        to={link.to}
                        className="text-sm text-gray-400 hover:text-white transition-colors"
                        onClick={(e) => handleLinkClick(e, link.requiresAuth)}
                      >
                        {link.name}
                      </link.component>
                    ) : (
                      <Link
                        to={link.to}
                        className="text-sm text-gray-400 hover:text-white transition-colors"
                        onClick={(e) => handleLinkClick(e, link.requiresAuth)}
                      >
                        {link.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom bar */}
        <div className="pt-8 mt-8 border-t border-gray-700">
          <div className="flex flex-col items-center gap-4 lg:flex-row lg:justify-between">
            {/* Logo and copyright */}
            <div className="flex items-center gap-2">
              <CommandLineIcon className="h-6 w-6 text-green-400" />
              <span className="text-white font-semibold">promptdump.io</span>
              <span className="text-sm text-gray-400 ml-2">
                &copy; {currentYear} PromptDump. All rights reserved.
              </span>
            </div>

            {/* Social links */}
            <div className="flex gap-6">
              {socialLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    // Prevent any window manipulation
                    e.stopPropagation();
                  }}
                  aria-label={link.name}
                >
                  {link.name === 'Twitter' ? <XIcon /> : link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Login Popup */}
      {showLoginPopup && (
        <Login isPopup={true} onClose={() => setShowLoginPopup(false)} />
      )}
    </footer>
  );
}

export default Footer;
