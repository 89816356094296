import OpenAI from 'openai';
import { requestQueue } from './apiRetry';

const openai = new OpenAI({
  apiKey: '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',
  baseURL: 'https://openrouter.ai/api/v1',
  defaultHeaders: {
    'HTTP-Referer': 'https://github.com/codeusorg',
    'X-Title': 'Newbolt'
  },
  dangerouslyAllowBrowser: true
});

export async function categorizePrompt(promptText) {
  try {
    console.log('Sending request to OpenRouter...');
    
    const operation = async () => {
      const response = await openai.chat.completions.create({
        model: "openai/gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `You are a helpful assistant that analyses prompts and categorises them. Return only a JSON object with no additional text.`
          },
          {
            role: "user",
            content: `Analyse this prompt and categorise it: "${promptText}". Return a JSON object with:
            {
              "category": "one of: video, image, text, code, audio, 3d",
              "subcategory": "specific type like animation, photo-editing, story, web-development",
              "tags": ["array", "of", "relevant", "keywords"],
              "purpose": "brief description of the goal"
            }`
          }
        ],
        temperature: 0.3,
        max_tokens: 500
      });

      console.log('Response received:', response);
      
      try {
        const content = response.choices[0].message.content.trim();
        console.log('Parsing content:', content);
        return JSON.parse(content);
      } catch (jsonError) {
        console.error('Error parsing JSON response:', jsonError);
        console.log('Raw content:', response.choices[0].message.content);
        return {
          category: 'uncategorized',
          subcategory: 'general',
          tags: [],
          purpose: 'Unknown'
        };
      }
    };

    return await requestQueue.add(operation);
  } catch (error) {
    console.error('Error categorizing prompt:', error);
    if (error.response?.status === 429 || error.message?.includes('rate limit')) {
      throw new Error('Rate limit exceeded. Please try again in a few minutes.');
    }
    return {
      category: 'uncategorized',
      subcategory: 'general',
      tags: [],
      purpose: 'Error: ' + (error.message || 'Unknown error')
    };
  }
}
