import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import React, { lazy, Suspense } from 'react';
import { LoadingSpinner } from '../components/LoadingSpinner';
import ProtectedRoute from '../components/ProtectedRoute';
import RateLimitError from '../components/errors/RateLimitError';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import SignUp from '../pages/SignUp';
import CategoryPage from '../pages/CategoryPage';
import CreativeWriting from '../pages/CreativeWriting';
import CodeGeneration from '../pages/CodeGeneration';
import ImagePrompts from '../pages/ImagePrompts';
import VideoPrompts from '../pages/VideoPrompts';
import TradingStrategies from '../pages/TradingStrategies';
import Writing from '../pages/Writing';
import Coding from '../pages/Coding';
import ArtDesign from '../pages/ArtDesign';
import BusinessMarketing from '../pages/BusinessMarketing';
import EducationLearning from '../pages/EducationLearning';
import HealthWellness from '../pages/HealthWellness';
import ScienceTechnology from '../pages/ScienceTechnology';
import LifestylePersonalDevelopment from '../pages/LifestylePersonalDevelopment';
import EntertainmentMedia from '../pages/EntertainmentMedia';
import SocialSciencesHumanities from '../pages/SocialSciencesHumanities';
import FinanceInvesting from '../pages/FinanceInvesting';
import TravelAdventure from '../pages/TravelAdventure';
import FoodCulinaryArts from '../pages/FoodCulinaryArts';
import ParentingRelationships from '../pages/ParentingRelationships';
import GamingEsports from '../pages/GamingEsports';
import SportsRecreation from '../pages/SportsRecreation';
import DIYCrafts from '../pages/DIYCrafts';
import EnvironmentSustainability from '../pages/EnvironmentSustainability';
import LanguageCommunication from '../pages/LanguageCommunication';
import GetStarted from '../pages/GetStarted';
import Home from '../pages/Home';
import Feed from '../pages/Feed';

// Lazy load components
const Profile = lazy(() => import('../pages/Profile'));
const Login = lazy(() => import('../pages/Login'));
const Explore = lazy(() => import('../pages/Explore'));
const About = lazy(() => import('../pages/About'));
const Contact = lazy(() => import('../pages/Contact'));
const Settings = lazy(() => import('../pages/Settings'));
const HelpCentre = lazy(() => import('../pages/HelpCentre'));
const FeatureRequest = lazy(() => import('../pages/FeatureRequest'));
const AllCategories = lazy(() => import('../components/AllCategories'));
const FAQ = lazy(() => import('../pages/FAQ'));
const Terms = lazy(() => import('../pages/policies/Terms'));
const Privacy = lazy(() => import('../pages/policies/Privacy'));
const Cookies = lazy(() => import('../pages/policies/Cookies'));
const Register = lazy(() => import('../pages/Register'));

// Custom error boundary component
class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      isRateLimit: false,
      retryAfter: 0
    };
  }

  static getDerivedStateFromError(error) {
    const isRateLimit = error.name === 'RateLimitError' || 
      error.message?.toLowerCase().includes('rate limit') ||
      error.message?.toLowerCase().includes('resource_exhausted');

    let retryAfter = 3600; // Default to 1 hour
    if (isRateLimit) {
      const match = error.message.match(/try again in (?:about )?(\d+) (?:hour|minute)s?/i);
      if (match) {
        const amount = parseInt(match[1]);
        const isHours = error.message.toLowerCase().includes('hour');
        retryAfter = isHours ? amount * 3600 : amount * 60;
      }
    }

    return { 
      hasError: true, 
      error,
      isRateLimit,
      retryAfter
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null, isRateLimit: false, retryAfter: 0 });
  };

  render() {
    if (this.state.hasError) {
      if (this.state.isRateLimit) {
        return (
          <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <RateLimitError 
              retryAfter={this.state.retryAfter}
              onRetry={this.handleRetry}
            />
          </div>
        );
      }

      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
          <div className="text-center p-8">
            <h1 className="text-2xl font-bold mb-4 text-white">Something went wrong</h1>
            <p className="text-gray-300 mb-4">We're sorry, but there was an error loading this page.</p>
            <button 
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-400 transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const SuspenseWrapper = ({ children }) => (
  <CustomErrorBoundary>
    <Suspense fallback={<LoadingSpinner />}>
      {children}
    </Suspense>
  </CustomErrorBoundary>
);

// Add Claude button
const ClaudeButton = () => (
  <button
    onClick={() => {
      navigator.clipboard.writeText('https://claude.ai/new');
      window.open('https://claude.ai/new', '_blank');
    }}
    className="p-2 bg-transparent hover:bg-orange-400 transition-colors"
  >
    <img src="/src/assets/Screenshot%202024-12-03%20011008.png" alt="Claude Icon" className="w-5 h-5" />
  </button>
);

// Example usage
const Buttons = () => (
  <div className="flex space-x-4">
    <ClaudeButton />
  </div>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    errorElement: (
      <ErrorBoundary>
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center p-8">
            <h1 className="text-2xl font-bold mb-4">Page Not Found</h1>
            <p className="text-gray-600 mb-4">The page you're looking for doesn't exist or has been moved.</p>
            <a href="/" className="btn-primary inline-block">
              Go Home
            </a>
          </div>
        </div>
      </ErrorBoundary>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: 'feed', element: <Feed /> },
      { path: 'profile', element: <SuspenseWrapper><Profile /></SuspenseWrapper> },
      { path: 'get-started', element: <SuspenseWrapper><GetStarted /></SuspenseWrapper> },
      { path: 'login', element: <SuspenseWrapper><Login /></SuspenseWrapper> },
      { path: 'register', element: <SuspenseWrapper><Register /></SuspenseWrapper> },
      { path: 'CreativeWriting', element: <CreativeWriting /> },
      { path: 'CodeGeneration', element: <CodeGeneration /> },
      { path: 'ImagePrompts', element: <ImagePrompts /> },
      { path: 'VideoPrompts', element: <VideoPrompts /> },
      { path: 'TradingStrategies', element: <TradingStrategies /> },
      { path: 'Writing', element: <Writing /> },
      { path: 'Coding', element: <Coding /> },
      { path: 'ArtDesign', element: <ArtDesign /> },
      { path: 'BusinessMarketing', element: <BusinessMarketing /> },
      { path: 'EducationLearning', element: <EducationLearning /> },
      { path: 'HealthWellness', element: <HealthWellness /> },
      { path: 'ScienceTechnology', element: <ScienceTechnology /> },
      { path: 'LifestylePersonalDevelopment', element: <LifestylePersonalDevelopment /> },
      { path: 'EntertainmentMedia', element: <EntertainmentMedia /> },
      { path: 'SocialSciencesHumanities', element: <SocialSciencesHumanities /> },
      { path: 'FinanceInvesting', element: <FinanceInvesting /> },
      { path: 'TravelAdventure', element: <TravelAdventure /> },
      { path: 'FoodCulinaryArts', element: <FoodCulinaryArts /> },
      { path: 'ParentingRelationships', element: <ParentingRelationships /> },
      { path: 'GamingEsports', element: <GamingEsports /> },
      { path: 'SportsRecreation', element: <SportsRecreation /> },
      { path: 'DIYCrafts', element: <DIYCrafts /> },
      { path: 'EnvironmentSustainability', element: <EnvironmentSustainability /> },
      { path: 'LanguageCommunication', element: <LanguageCommunication /> },
      { 
        path: 'profile', 
        element: (
          <ProtectedRoute>
            <SuspenseWrapper><Profile /></SuspenseWrapper>
          </ProtectedRoute>
        )
      },
      { path: 'profile/:userId', element: <SuspenseWrapper><Profile /></SuspenseWrapper> },
      { path: 'explore', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/trending', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/projects', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/bookmarked', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/collections', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/templates', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/analytics', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'explore/api', element: <SuspenseWrapper><Explore /></SuspenseWrapper> },
      { path: 'category/:categoryId', element: <SuspenseWrapper><CategoryPage /></SuspenseWrapper> },
      { path: 'about', element: <SuspenseWrapper><About /></SuspenseWrapper> },
      { path: 'contact', element: <SuspenseWrapper><Contact /></SuspenseWrapper> },
      { 
        path: 'settings', 
        element: (
          <ProtectedRoute>
            <SuspenseWrapper><Settings /></SuspenseWrapper>
          </ProtectedRoute>
        )
      },
      { path: 'helpcentre', element: <SuspenseWrapper><HelpCentre /></SuspenseWrapper> },
      { path: 'feature-request', element: <SuspenseWrapper><FeatureRequest /></SuspenseWrapper> },
      { path: 'all-categories', element: <SuspenseWrapper><AllCategories /></SuspenseWrapper> },
      { path: 'faq', element: <SuspenseWrapper><FAQ /></SuspenseWrapper> },
      { path: 'terms', element: <SuspenseWrapper><Terms /></SuspenseWrapper> },
      { path: 'privacy', element: <SuspenseWrapper><Privacy /></SuspenseWrapper> },
      { path: 'cookies', element: <SuspenseWrapper><Cookies /></SuspenseWrapper> },
      {
        path: '/signup',
        element: <SuspenseWrapper><SignUp /></SuspenseWrapper>
      }
    ]
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
});

export { ClaudeButton, Buttons };
