import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import { enhancePrompt, randomisePrompt, RateLimitError, detectPromptCategory } from '../../utils/api';

const PromptModal = ({ isOpen, onClose, onPromptSaved, user }) => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [selectedModel, setSelectedModel] = useState('openai/gpt-3.5-turbo');

  const handleEnhance = async () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt first');
      return;
    }
    
    setIsEnhancing(true);
    try {
      const enhanced = await enhancePrompt(prompt);
      setPrompt(enhanced);
      toast.success('Prompt enhanced!');
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      if (error instanceof RateLimitError) {
        const minutes = Math.ceil(error.retryAfter / 60);
        toast.error(`The AI service is currently busy. Please try again in ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}.`);
      } else {
        toast.error(error.message || 'Failed to enhance prompt');
      }
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleRandomise = async () => {
    setIsLoading(true);
    try {
      const randomPrompt = await randomisePrompt();
      if (!randomPrompt) {
        throw new Error('Could not generate random prompt');
      }
      setPrompt(randomPrompt);
      toast.success('Generated random prompt!');
    } catch (error) {
      console.error('Error randomising prompt:', error);
      if (error instanceof RateLimitError) {
        const minutes = Math.ceil(error.retryAfter / 60);
        toast.error(`The AI service is currently busy. Please try again in ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}.`);
      } else {
        const errorMessage = error.message.toLowerCase().includes('rate limit') 
          ? 'The service is currently busy. Please try again in about an hour.'
          : error.message || 'Failed to generate random prompt';
        toast.error(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt first');
      return;
    }

    if (!user) {
      console.error('No user object:', user);
      toast.error('Please sign in to save prompts');
      return;
    }

    if (!user.uid) {
      console.error('No user.uid:', user);
      toast.error('Please sign in to save prompts');
      return;
    }

    setIsLoading(true);
    let detectedCategory = 'uncategorized';
    
    try {
      // Try to detect the category using AI
      detectedCategory = await detectPromptCategory(prompt);
      console.log('Detected category:', detectedCategory);
    } catch (error) {
      console.error('Error detecting category:', error);
      // Continue with saving even if category detection fails
      if (error instanceof RateLimitError) {
        const minutes = Math.ceil(error.retryAfter / 60);
        toast.error(`Category detection is currently busy. Saving as uncategorized. Try editing later.`);
      }
    }
    
    try {
      const timestamp = serverTimestamp();
      const authorData = {
        id: user.uid,
        displayName: user.displayName || 'Anonymous',
        photoURL: user.photoURL || null,
        email: user.email || null
      };
      
      const promptData = {
        content: prompt.trim(),
        userId: user.uid,
        createdAt: timestamp,
        updatedAt: timestamp,
        enhanced: isEnhancing,
        author: authorData,
        categoryId: detectedCategory,
        model: selectedModel
      };

      // Log the data being saved
      console.log('Saving prompt data:', promptData);

      // Validate all fields are defined
      Object.entries(promptData).forEach(([key, value]) => {
        if (value === undefined) {
          throw new Error(`Invalid field value: ${key} cannot be undefined`);
        }
      });

      const docRef = await addDoc(collection(db, 'prompts'), promptData);
      console.log('Prompt saved successfully with ID:', docRef.id);

      toast.success('Prompt saved successfully!');
      if (onPromptSaved) {
        onPromptSaved(docRef.id);
      }
      setPrompt('');
      onClose();
    } catch (error) {
      console.error('Error saving prompt:', error);
      const errorMessage = error.message.includes('rate limit') 
        ? 'Rate limit exceeded. Please try again in a few minutes.'
        : error.message || 'Failed to save prompt. Please try again.';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>
        
        <div className="relative bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 shadow-xl">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-4 dark:text-white">Dump Your Prompt</h2>
          
          <div className="space-y-4">
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter your prompt here..."
              className="w-full h-32 p-3 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />

            <div className="mb-4">
              <label htmlFor="defaultModel" className="block text-sm font-medium text-gray-400 mb-2">AI Model</label>
              <select
                id="defaultModel"
                name="defaultModel"
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                className="form-select w-full bg-gray-700 border-gray-600 text-white rounded-lg"
              >
                <option value="openai/gpt-3.5-turbo">GPT-3.5 Turbo</option>
                <option value="openai/gpt-4">GPT-4</option>
                <option value="anthropic/claude-2">Claude 2</option>
              </select>
            </div>

            <div className="flex flex-wrap gap-3 mt-4">
              <button
                onClick={handleEnhance}
                disabled={isLoading || isEnhancing}
                className="btn-secondary"
              >
                {isEnhancing ? 'Enhancing...' : 'Enhance'}
              </button>
              
              <button
                onClick={handleRandomise}
                disabled={isLoading}
                className="btn-secondary"
              >
                Randomise
              </button>

              <button
                onClick={handleSave}
                disabled={isLoading}
                className="btn-primary"
              >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptModal;
