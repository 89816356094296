import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';

const formatRetryTime = (seconds) => {
  if (seconds >= 3600) {
    const hours = Math.ceil(seconds / 3600);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  } else if (seconds >= 60) {
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  }
  return `${seconds} seconds`;
};

const RateLimitError = ({ retryAfter, onRetry }) => {
  return (
    <div className="min-h-[300px] flex flex-col items-center justify-center p-8 rounded-lg bg-gray-800/50">
      <ClockIcon className="w-16 h-16 text-yellow-500 mb-4" />
      <h2 className="text-2xl font-bold text-white mb-2">AI Service Rate Limit</h2>
      <p className="text-gray-300 text-center mb-4">
        We've hit the AI service rate limit. This usually happens when there are too many requests in a short time.
      </p>
      <p className="text-gray-300 text-center mb-6">
        Please wait {formatRetryTime(retryAfter)} before trying again. Your work is saved and you can continue once the limit resets.
      </p>
      <div className="flex gap-4">
        <button
          onClick={onRetry}
          className="px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-colors"
        >
          Try Again
        </button>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 border border-gray-600 text-gray-300 rounded-lg hover:bg-gray-700/50 transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};

export default RateLimitError;
