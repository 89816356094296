import React from 'react';

function ImagePrompts() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Image Prompts</h1>
      <p>Welcome to the Image Prompts category page.</p>
    </div>
  );
}

export default ImagePrompts;
