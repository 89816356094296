import React from 'react';
import RateLimitError from './RateLimitError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      error: null,
      errorInfo: null 
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    
    // Log the error to your error reporting service
    console.error('Error caught by boundary:', error, errorInfo);

    // If it's a rate limit error, set a timer to auto-retry
    if (error.name === 'RateLimitError' && error.retryAfter) {
      setTimeout(() => {
        this.handleRetry();
      }, error.retryAfter * 1000);
    }
  }

  handleRetry = () => {
    this.setState({ error: null, errorInfo: null });
    if (this.props.onRetry) {
      this.props.onRetry();
    }
  }

  render() {
    if (this.state.error) {
      // Check if it's a rate limit error
      if (this.state.error.name === 'RateLimitError') {
        return (
          <RateLimitError 
            retryAfter={this.state.error.retryAfter} 
            onRetry={this.handleRetry}
          />
        );
      }

      // Default error UI
      return (
        <div className="min-h-[300px] flex flex-col items-center justify-center p-8 rounded-lg bg-gray-800/50">
          <h2 className="text-red-500 text-xl font-semibold mb-4">Something went wrong</h2>
          <p className="text-gray-300 text-center mb-6">
            We apologize for the inconvenience. Please try again later.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
