import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, query, orderBy, limit, getDocs, Timestamp, getDoc, doc, where, startAfter, addDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import { PromptCard } from '../components/prompt/PromptCard';
import PromptModal from '../components/prompt/PromptModal';
import toast from 'react-hot-toast';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import { CopyDateButton } from '../components/buttons/CopyDateButton';
import { TrashIcon } from '@heroicons/react/24/solid';

function Feed() {
  const { user } = useAuth();
  const [feedPrompts, setFeedPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const [bookmarkedPromptIds, setBookmarkedPromptIds] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPrompts, setSelectedPrompts] = useState(new Set());
  const feedRef = useRef(null);
  const observer = useRef(null);
  const animationFrameId = useRef(null);
  const BATCH_SIZE = 10;

  // Define fetchBookmarkedPrompts with useCallback
  const fetchBookmarkedPrompts = useCallback(async () => {
    if (!user) return;

    try {
      const bookmarksRef = collection(db, 'bookmarks');
      const q = query(bookmarksRef, where('userId', '==', user?.id));
      const querySnapshot = await getDocs(q);
      
      const bookmarkedIds = new Set();
      querySnapshot.forEach(doc => {
        const data = doc.data();
        bookmarkedIds.add(data.promptId);
      });
      
      setBookmarkedPromptIds(bookmarkedIds);
    } catch (error) {
      console.error('Error fetching bookmarked prompts:', error);
      toast.error('Failed to fetch bookmarks');
    }
  }, [user, setBookmarkedPromptIds]);

  // Infinite scroll using Intersection Observer
  const lastPromptRef = useCallback(node => {
    if (loading) return;
    
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMorePrompts();
      }
    });
    
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // Auto-scroll functionality
  useEffect(() => {
    if (!feedRef.current || !feedPrompts.length) return;

    const container = feedRef.current;
    const SCROLL_SPEED = 1; // pixels per frame
    let resetInProgress = false;

    const scroll = () => {
      if (!isAutoScrolling || resetInProgress) {
        return;
      }

      const maxScroll = container.scrollHeight - container.clientHeight;
      
      // If we're near the bottom, reset to top
      if (container.scrollTop >= maxScroll - 10) {
        resetInProgress = true;
        container.scrollTo({
          top: 0,
          behavior: 'auto'
        });
        
        // Wait a brief moment before resuming scroll
        setTimeout(() => {
          resetInProgress = false;
          if (isAutoScrolling) {
            animationFrameId.current = requestAnimationFrame(scroll);
          }
        }, 100);
        return;
      }

      // Normal scrolling
      container.scrollTo({
        top: container.scrollTop + SCROLL_SPEED,
        behavior: 'auto'
      });
      
      if (isAutoScrolling) {
        animationFrameId.current = requestAnimationFrame(scroll);
      }
    };

    // Only start scrolling if isAutoScrolling is true
    if (isAutoScrolling) {
      animationFrameId.current = requestAnimationFrame(scroll);
    }

    // Cleanup function
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = null;
      }
    };
  }, [isAutoScrolling, feedPrompts]); // Only re-run when these dependencies change

  // Toggle auto-scroll
  const toggleAutoScroll = useCallback(() => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }
    setIsAutoScrolling(prev => !prev);
  }, []);

  // Handle feed click to pause scrolling
  const handleFeedClick = useCallback((e) => {
    // Prevent event bubbling
    e.stopPropagation();
    if (isAutoScrolling) {
      setIsAutoScrolling(false);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = null;
      }
    }
  }, [isAutoScrolling]);

  // Remove the auto-start effect that was causing issues
  useEffect(() => {
    if (feedPrompts.length > 0 && !loading) {
      setError(null);
    }
  }, [feedPrompts, loading]);

  // Helper function to fetch author data
  const fetchAuthorData = async (userId) => {
    if (!userId) {
      return {
        id: null,
        displayName: 'User',
        photoURL: null
      };
    }
    
    try {
      // Try to get the user data from the users collection first
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          id: userId,
          displayName: userData.name || userData.displayName || 'User',
          photoURL: userData.photoURL,
          email: userData.email
        };
      }

      // If no user document exists, try to get from the prompt
      const promptAuthorRef = doc(db, 'prompts', userId);
      const promptAuthorDoc = await getDoc(promptAuthorRef);
      if (promptAuthorDoc.exists()) {
        const data = promptAuthorDoc.data();
        if (data.author) {
          return {
            id: userId,
            displayName: data.author.name || data.author.displayName || 'User',
            photoURL: data.author.photoURL,
            email: data.author.email
          };
        }
      }

      // If no data found anywhere, return default user
      return {
        id: userId,
        displayName: 'User',
        photoURL: null
      };
    } catch (error) {
      console.error('Error fetching author data:', error);
      return {
        id: userId,
        displayName: 'User',
        photoURL: null
      };
    }
  };

  // Helper function to create a prompt object
  const createPromptObject = (doc, data) => {
    // Ensure we have complete author data
    const authorData = {
      id: data.userId || data.author?.id,
      displayName: data.author?.name || data.author?.displayName || 'User',
      photoURL: data.author?.photoURL,
      email: data.author?.email
    };

    return {
      id: doc.id,
      content: data.content,
      createdAt: data.createdAt?.toDate?.() || new Date(data.createdAt),
      categoryId: data.categoryId || data.category || 'uncategorized',
      enhanced: data.enhanced || false,
      author: authorData,
      isBookmarked: bookmarkedPromptIds.has(doc.id)
    };
  };

  // Fetch and update prompts with author data
  const fetchPromptsWithAuthors = async () => {
    setLoading(true);
    try {
      const promptsRef = collection(db, 'prompts');
      const q = query(promptsRef, orderBy('createdAt', 'desc'), limit(BATCH_SIZE));
      const querySnapshot = await getDocs(q);
      
      const promptsWithAuthors = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          // Get fresh author data for each prompt
          const authorData = await fetchAuthorData(data.userId || data.author?.id);
          return {
            id: doc.id,
            ...data,
            author: authorData // Override with fresh author data
          };
        })
      );
      
      setFeedPrompts(promptsWithAuthors);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === BATCH_SIZE);
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Failed to load prompts');
      toast.error('Failed to load feed');
    } finally {
      setLoading(false);
    }
  };

  // Fetch more prompts when scrolling
  const fetchMorePrompts = async () => {
    if (!hasMore || loading || !lastDoc) return;
    
    try {
      const promptsRef = collection(db, 'prompts');
      const q = query(
        promptsRef,
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(BATCH_SIZE)
      );
      
      const querySnapshot = await getDocs(q);
      const newPromptsWithAuthors = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const authorData = await fetchAuthorData(data.userId || data.author?.id);
          return {
            id: doc.id,
            ...data,
            author: authorData
          };
        })
      );
      
      setFeedPrompts(current => [...current, ...newPromptsWithAuthors]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === BATCH_SIZE);
    } catch (error) {
      console.error('Error fetching more prompts:', error);
      toast.error('Failed to load more prompts');
    }
  };

  // Handle bookmark toggle
  const handleBookmarkToggle = async (promptId, isBookmarked) => {
    if (!user) {
      toast.error('Please sign in to bookmark prompts');
      return;
    }

    try {
      const bookmarksRef = collection(db, 'bookmarks');
      const promptToBookmark = feedPrompts.find(p => p.id === promptId);
      
      if (!promptToBookmark) {
        throw new Error('Prompt not found');
      }

      if (isBookmarked) {
        // Find and remove the bookmark document
        const bookmarkQuery = query(
          bookmarksRef,
          where('userId', '==', user.id),
          where('promptId', '==', promptId)
        );
        const bookmarkSnapshot = await getDocs(bookmarkQuery);
        
        if (!bookmarkSnapshot.empty) {
          await deleteDoc(bookmarkSnapshot.docs[0].ref);
          setBookmarkedPromptIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(promptId);
            return newSet;
          });
          
          // Update the prompt in the feed to show it's not bookmarked
          setFeedPrompts(prev => 
            prev.map(p => p.id === promptId ? {
              ...p,
              isBookmarked: false,
              bookmarkId: null
            } : p)
          );
          
          toast.success('Bookmark removed');
        }
      } else {
        // Create a new bookmark
        const bookmarkData = {
          userId: user.id,
          promptId: promptId,
          createdAt: Timestamp.now(),
          prompt: {
            ...promptToBookmark,
            bookmarkedAt: Timestamp.now(),
            isBookmarked: true
          }
        };
        
        const docRef = await addDoc(bookmarksRef, bookmarkData);
        
        // Update bookmarked IDs set
        setBookmarkedPromptIds(prev => {
          const newSet = new Set(prev);
          newSet.add(promptId);
          return newSet;
        });
        
        // Update the prompt in the feed to show it's bookmarked
        setFeedPrompts(prev => 
          prev.map(p => p.id === promptId ? {
            ...p,
            isBookmarked: true,
            bookmarkId: docRef.id
          } : p)
        );
        
        toast.success('Prompt bookmarked');
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      toast.error('Failed to update bookmark');
    }
  };

  // Handle prompt selection
  const handleSelect = (prompt) => {
    setSelectedPrompts(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(prompt.id)) {
        newSelected.delete(prompt.id);
      } else {
        newSelected.add(prompt.id);
      }
      return newSelected;
    });
  };

  // Handle delete selected prompts
  const handleDeleteSelected = async () => {
    if (selectedPrompts.size === 0) return;
    
    if (window.confirm(`Are you sure you want to delete ${selectedPrompts.size} selected prompt${selectedPrompts.size > 1 ? 's' : ''}?`)) {
      try {
        // Add your delete logic here
        // const deletePromises = Array.from(selectedPrompts).map(id => deletePrompt(id));
        // await Promise.all(deletePromises);
        
        setFeedPrompts(prev => prev.filter(prompt => !selectedPrompts.has(prompt.id)));
        setSelectedPrompts(new Set());
        toast.success(`Successfully deleted ${selectedPrompts.size} prompt${selectedPrompts.size > 1 ? 's' : ''}`);
      } catch (error) {
        toast.error('Failed to delete prompts');
        console.error('Error deleting prompts:', error);
      }
    }
  };

  // Add useEffect to keep bookmark state in sync
  useEffect(() => {
    if (user) {
      fetchBookmarkedPrompts();
    }
  }, [user, fetchBookmarkedPrompts]);

  const isDarkMode = false; // This is a placeholder. Replace with actual theme detection logic.

  // Modify the useEffect to wait for user authentication
  useEffect(() => {
    if (user) {
      fetchPromptsWithAuthors();
      fetchBookmarkedPrompts();
    }
  }, [user, fetchPromptsWithAuthors, fetchBookmarkedPrompts]);

  return (
    <div className="min-h-screen dark:bg-[rgb(var(--background))] bg-white">
      <div className="container max-w-4xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold dark:text-white">Dump Feed</h1>
          <div className="flex items-center gap-4">
            {user && (
              <button
                onClick={() => setIsModalOpen(true)}
                className="btn-primary"
              >
                Dump Prompt
              </button>
            )}
            <button
              onClick={() => setIsAutoScrolling(!isAutoScrolling)}
              className="p-2 rounded-lg dark:bg-gray-800 bg-gray-100 dark:hover:bg-gray-700 hover:bg-gray-200 transition-colors"
              title={isAutoScrolling ? 'Pause auto-scroll' : 'Resume auto-scroll'}
            >
              {isAutoScrolling ? (
                <PauseIcon className="w-5 h-5 dark:text-gray-300 text-gray-600" />
              ) : (
                <PlayIcon className="w-5 h-5 dark:text-gray-300 text-gray-600" />
              )}
            </button>
          </div>
        </div>

        {selectedPrompts.size > 0 && (
          <div className="sticky top-0 z-10 bg-white dark:bg-gray-900 p-4 mb-6 rounded-lg border dark:border-gray-800 border-gray-200 shadow-sm">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-600 dark:text-gray-400">
                {selectedPrompts.size} prompt{selectedPrompts.size > 1 ? 's' : ''} selected
              </span>
              <button
                onClick={handleDeleteSelected}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-red-600 hover:bg-red-700 text-white transition-colors duration-200"
              >
                <TrashIcon className="w-5 h-5" />
                <span>Delete Selected</span>
              </button>
            </div>
          </div>
        )}
        
        {error && (
          <div className="text-red-500 mb-4">{error}</div>
        )}

        <div 
          ref={feedRef} 
          className="h-[calc(100vh-12rem)] overflow-y-scroll scrollbar-hide"
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          onClick={handleFeedClick}
        >
          {feedPrompts.length > 0 ? (
            feedPrompts.map((prompt, index) => (
              <div
                key={prompt.id}
                ref={index === feedPrompts.length - 1 ? lastPromptRef : null}
                className="mb-4" // Add spacing between cards
              >
                <PromptCard
                  {...prompt}
                  showAuthor={true}
                  showActions={true}
                  isBookmarked={bookmarkedPromptIds.has(prompt.id)}
                  onBookmarkToggle={handleBookmarkToggle}
                  showBookmarks={false}
                  isSelected={selectedPrompts.has(prompt.id)}
                  onSelect={handleSelect}
                />
              </div>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No prompts found
            </div>
          )}
          
          {loading && (
            <div className="py-4">
              <div className="animate-pulse space-y-4">
                {[1, 2].map((i) => (
                  <div key={i} className="h-32 bg-slate-700/50 rounded-lg" />
                ))}
              </div>
            </div>
          )}
          
          {!loading && !hasMore && feedPrompts.length > 0 && (
            <div className="text-center py-8 text-gray-500">
              You've reached the end of the feed
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <PromptModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onPromptSaved={() => {
            fetchPromptsWithAuthors();
            setIsModalOpen(false);
          }}
          user={auth.currentUser}
        />
      )}
    </div>
  );
}

export default Feed;