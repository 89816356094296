import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromptCard from '../components/PromptCard';
import { MagnifyingGlassIcon, FunnelIcon, ChevronDownIcon, XMarkIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { getCategoryDetails } from '../utils/api';

// Category data mapping
const categoryData = {
  1: {
    name: "Creative Writing",
    icon: "✍️",
    description: "Explore creative writing prompts to inspire your next story",
    subcategories: ['Short Stories', 'Dialogue Writing', 'Poetry', 'Novel Writing']
  },
  2: {
    name: "Code Generation",
    icon: "💻",
    description: "Discover prompts for generating efficient and clean code",
    subcategories: ['Algorithms', 'Web Development', 'Data Structures', 'API Design']
  },
  3: {
    name: "Image Prompts",
    icon: "🎨",
    description: "Find prompts for creating stunning visual artwork",
    subcategories: ['Digital Art', 'Character Design', 'Landscapes', 'Abstract']
  },
  4: {
    name: "Video Prompts",
    icon: "🎥",
    description: "Create engaging video content with these prompts",
    subcategories: ['Animation', 'Motion Graphics', 'Film Making', 'Video Effects']
  },
  5: {
    name: "Trading Strategies",
    icon: "📈",
    description: "Explore prompts for developing trading and investment strategies",
    subcategories: ['Technical Analysis', 'Risk Management', 'Portfolio Strategy', 'Market Research']
  },
  6: {
    name: "Writing",
    icon: "📝",
    description: "Master different writing styles and techniques",
    subcategories: ['Blog Posts', 'Technical Writing', 'Content Marketing', 'Copywriting']
  },
  7: {
    name: "Coding",
    icon: "⌨️",
    description: "Enhance your coding skills across different domains",
    subcategories: ['Frontend', 'Backend', 'Mobile Development', 'DevOps']
  },
  8: {
    name: "Art & Design",
    icon: "🎯",
    description: "Create compelling visual designs and artwork",
    subcategories: ['UI/UX Design', 'Graphic Design', 'Illustration', 'Brand Design']
  },
  9: {
    name: "Business & Marketing",
    icon: "💼",
    description: "Generate effective business and marketing strategies",
    subcategories: ['Digital Marketing', 'Social Media', 'Email Marketing', 'Content Strategy']
  },
  10: {
    name: "Education & Learning",
    icon: "📚",
    description: "Create educational content and learning materials",
    subcategories: ['Course Design', 'Lesson Plans', 'Educational Games', 'Assessment Tools']
  },
  11: {
    name: "Science & Research",
    icon: "🔬",
    description: "Generate scientific hypotheses and research approaches",
    subcategories: ['Experimental Design', 'Data Analysis', 'Literature Review', 'Research Methods']
  },
  12: {
    name: "Health & Wellness",
    icon: "🏥",
    description: "Develop health and wellness content and strategies",
    subcategories: ['Fitness Programs', 'Nutrition Plans', 'Mental Health', 'Wellness Tips']
  },
  13: {
    name: "Technology",
    icon: "🤖",
    description: "Explore cutting-edge technology applications",
    subcategories: ['AI/ML', 'Blockchain', 'IoT', 'Cloud Computing']
  },
  14: {
    name: "Music & Audio",
    icon: "🎵",
    description: "Create and explore musical and audio content",
    subcategories: ['Music Composition', 'Sound Design', 'Audio Production', 'Podcast Creation']
  },
  15: {
    name: "Gaming",
    icon: "🎮",
    description: "Design and develop gaming experiences",
    subcategories: ['Game Design', 'Level Design', 'Character Development', 'Game Mechanics']
  },
  16: {
    name: "Social Media",
    icon: "📱",
    description: "Create engaging social media content",
    subcategories: ['Content Creation', 'Community Management', 'Platform Strategy', 'Analytics']
  },
  17: {
    name: "Data Analysis",
    icon: "📊",
    description: "Generate insights from data analysis",
    subcategories: ['Data Visualization', 'Statistical Analysis', 'Business Intelligence', 'Predictive Analytics']
  },
  18: {
    name: "Personal Development",
    icon: "🌱",
    description: "Foster personal growth and development",
    subcategories: ['Goal Setting', 'Productivity', 'Leadership', 'Self Improvement']
  },
  19: {
    name: "Travel & Adventure",
    icon: "✈️",
    description: "Plan and document travel experiences",
    subcategories: ['Trip Planning', 'Travel Writing', 'Photography Tips', 'Cultural Guides']
  },
  20: {
    name: "Food & Cooking",
    icon: "🍳",
    description: "Create culinary content and recipes",
    subcategories: ['Recipe Development', 'Food Photography', 'Cooking Techniques', 'Menu Planning']
  },
  21: {
    name: "Fashion & Style",
    icon: "👗",
    description: "Explore fashion and style concepts",
    subcategories: ['Fashion Design', 'Style Guides', 'Trend Analysis', 'Wardrobe Planning']
  },
  22: {
    name: "Sports & Fitness",
    icon: "🏃",
    description: "Develop sports and fitness content",
    subcategories: ['Training Programs', 'Sports Analysis', 'Technique Guides', 'Equipment Reviews']
  },
  23: {
    name: "Home & DIY",
    icon: "🏠",
    description: "Create home improvement and DIY projects",
    subcategories: ['Home Decor', 'DIY Projects', 'Organization', 'Gardening']
  },
  24: {
    name: "Photography",
    icon: "📸",
    description: "Master photography techniques and styles",
    subcategories: ['Portrait', 'Landscape', 'Product Photography', 'Post-Processing']
  }
};

function CategoryPage() {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    subcategory: 'all',
    sortBy: 'newest',
    timeRange: 'all',
    difficulty: 'all',
    length: 'all',
    tags: []
  });

  const sortOptions = ['Newest', 'Most Popular', 'Most Liked', 'Most Used', 'Most Saved'];
  const timeRanges = ['All Time', 'Past 24 Hours', 'Past Week', 'Past Month', 'Past Year'];
  const difficultyLevels = ['All Levels', 'Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const lengthOptions = ['All Lengths', 'Short', 'Medium', 'Long', 'Very Long'];
  const availableTags = ['Featured', 'Trending', 'Popular', 'New', 'Verified', 'Community Choice'];

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const currentCategory = categoryData[categoryId];
        
        if (!currentCategory) {
          throw new Error('Category not found');
        }

        // Generate mock prompts based on the category
        const mockPrompts = [
          {
            id: `${categoryId}-1`,
            content: generatePromptContent(currentCategory.name, 1),
            categoryId: currentCategory.name,
            subcategory: currentCategory.subcategories[0],
            tags: [currentCategory.name.toLowerCase(), 'featured', 'trending', 'verified'],
            createdAt: new Date().toISOString(),
            author: "Sarah Chen",
            authorId: "sarah123",
            authorPhoto: "https://ui-avatars.com/api/?name=Sarah+Chen&background=1f2937&color=4ade80",
            difficulty: "intermediate",
            length: "medium",
            usageCount: 1250,
            saveCount: 890,
            likeCount: 456
          },
          {
            id: `${categoryId}-2`,
            content: generatePromptContent(currentCategory.name, 2),
            categoryId: currentCategory.name,
            subcategory: currentCategory.subcategories[1],
            tags: [currentCategory.name.toLowerCase(), 'popular', 'community choice'],
            createdAt: new Date(Date.now() - 86400000).toISOString(), // 1 day ago
            author: "James Wilson",
            authorId: "james456",
            authorPhoto: "https://ui-avatars.com/api/?name=James+Wilson&background=1f2937&color=4ade80",
            difficulty: "beginner",
            length: "short",
            usageCount: 980,
            saveCount: 670,
            likeCount: 345
          },
          {
            id: `${categoryId}-3`,
            content: generatePromptContent(currentCategory.name, 1),
            categoryId: currentCategory.name,
            subcategory: currentCategory.subcategories[2] || currentCategory.subcategories[0],
            tags: [currentCategory.name.toLowerCase(), 'new'],
            createdAt: new Date(Date.now() - 3600000).toISOString(), // 1 hour ago
            author: "Elena Rodriguez",
            authorId: "elena789",
            authorPhoto: "https://ui-avatars.com/api/?name=Elena+Rodriguez&background=1f2937&color=4ade80",
            difficulty: "expert",
            length: "long",
            usageCount: 120,
            saveCount: 85,
            likeCount: 42
          }
        ];

        setCategory(currentCategory);
        setPrompts(mockPrompts);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [categoryId]);

  // Helper function to generate relevant prompt content
  function generatePromptContent(categoryName, promptNumber) {
    const promptTemplates = {
      "Creative Writing": [
        "Write a short story about a character who discovers they can communicate with plants. Include themes of environmental awareness and personal growth.",
        "Create a dialogue between two characters who speak different languages but must work together to solve a mystery."
      ],
      "Code Generation": [
        "Create an efficient sorting algorithm that handles duplicate values and maintains stability. Include time and space complexity analysis.",
        "Design a RESTful API for a social media platform with authentication, post creation, and user interaction endpoints."
      ],
      "Image Prompts": [
        "Design a cyberpunk cityscape at sunset with neon signs and flying vehicles. Focus on atmospheric perspective and color theory.",
        "Create a character design for a wise ancient tree spirit. Include details about textures and magical elements."
      ],
      "Video Prompts": [
        "Create a 30-second animation showcasing a transformation sequence from seed to full-grown tree. Focus on smooth transitions.",
        "Design a motion graphics intro for a tech news channel. Include dynamic typography and modern visual effects."
      ],
      "Trading Strategies": [
        "Develop a trading strategy that combines momentum indicators with volume analysis for cryptocurrency markets.",
        "Create a risk management system that adjusts position sizes based on market volatility and account balance."
      ],
      "Writing": [
        "Write a comprehensive blog post about the future of artificial intelligence in everyday life. Include real-world examples and expert insights.",
        "Create a technical documentation guide for a new software feature, including setup instructions and troubleshooting tips."
      ],
      "Coding": [
        "Build a responsive navigation component using React and Tailwind CSS with dropdown menus and mobile optimization.",
        "Implement a caching system for a Node.js API to improve performance and reduce database load."
      ],
      "Art & Design": [
        "Design a modern, minimalist logo for a sustainable fashion brand. Include color palette and usage guidelines.",
        "Create a user interface for a meditation app focusing on calm colors and intuitive navigation."
      ],
      "Business & Marketing": [
        "Develop a comprehensive social media marketing strategy for a new fitness app launch.",
        "Create an email marketing campaign for a seasonal product sale with A/B testing variants."
      ],
      "Education & Learning": [
        "Design an interactive lesson plan for teaching basic programming concepts to middle school students.",
        "Create a gamified learning module for teaching foreign language vocabulary."
      ],
      "Science & Research": [
        "Design an experiment to test the effects of different light wavelengths on plant growth.",
        "Create a data collection protocol for a study on urban air quality patterns."
      ],
      "Health & Wellness": [
        "Develop a 30-day wellness program combining exercise, nutrition, and mindfulness practices.",
        "Create a stress management workshop outline with practical exercises and techniques."
      ],
      "Technology": [
        "Design an AI model architecture for real-time object detection in autonomous vehicles.",
        "Create a blockchain-based solution for supply chain transparency in the food industry."
      ],
      "Music & Audio": [
        "Compose a dynamic background score for a product advertisement that evolves with the visuals.",
        "Design a sound effects library for a mobile gaming application."
      ],
      "Gaming": [
        "Design a balanced progression system for a role-playing game including skills and rewards.",
        "Create a level design document for a puzzle platformer with increasing complexity."
      ],
      "Social Media": [
        "Create a month's worth of engaging social media content for a sustainable fashion brand.",
        "Design a viral challenge campaign that aligns with a brand's values and goals."
      ],
      "Data Analysis": [
        "Create a dashboard design for visualizing e-commerce sales trends and customer behavior.",
        "Develop a predictive model for customer churn using historical transaction data."
      ],
      "Personal Development": [
        "Design a 90-day personal transformation program focusing on habits, mindset, and goals.",
        "Create a productivity system combining time management and energy optimization."
      ],
      "Travel & Adventure": [
        "Write a detailed travel guide for a hidden gem destination, including local experiences.",
        "Create a photography-focused itinerary for capturing iconic landmarks in the best light."
      ],
      "Food & Cooking": [
        "Develop a series of quick, healthy dinner recipes for busy professionals.",
        "Create a food photography guide for capturing stunning plated dishes."
      ],
      "Fashion & Style": [
        "Design a capsule wardrobe guide for different body types and personal styles.",
        "Create a trend forecast report for the upcoming season with mood boards."
      ],
      "Sports & Fitness": [
        "Design a progressive calisthenics program for building strength without equipment.",
        "Create a sports performance analysis template for team coaches."
      ],
      "Home & DIY": [
        "Design a small space organization system using sustainable materials.",
        "Create a step-by-step guide for building a vertical garden in an urban setting."
      ],
      "Photography": [
        "Create a lighting setup guide for professional portrait photography.",
        "Design a post-processing workflow for landscape photography enhancement."
      ]
    };

    return promptTemplates[categoryName][promptNumber - 1] || "Default prompt content";
  }

  const filteredPrompts = prompts.filter(prompt => {
    const matchesSearch = prompt.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         prompt.author.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesSubcategory = filters.subcategory === 'all' || 
                              prompt.subcategory.toLowerCase() === filters.subcategory.toLowerCase();
    const matchesDifficulty = filters.difficulty === 'all' ||
                             prompt.difficulty === filters.difficulty.toLowerCase();
    const matchesLength = filters.length === 'all' ||
                         prompt.length === filters.length.toLowerCase();
    const matchesTags = filters.tags.length === 0 ||
                       filters.tags.some(tag => prompt.tags.includes(tag.toLowerCase()));
    
    return matchesSearch && matchesSubcategory && matchesDifficulty && matchesLength && matchesTags;
  });

  // Sort prompts based on selected filter
  const sortedPrompts = [...filteredPrompts].sort((a, b) => {
    switch (filters.sortBy.toLowerCase()) {
      case 'newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'most popular':
        return b.usageCount - a.usageCount;
      case 'most liked':
        return b.likeCount - a.likeCount;
      case 'most used':
        return b.usageCount - a.usageCount;
      case 'most saved':
        return b.saveCount - a.saveCount;
      default:
        return 0;
    }
  });

  // Apply time range filter
  const timeFilteredPrompts = sortedPrompts.filter(prompt => {
    const promptDate = new Date(prompt.createdAt);
    const now = new Date();
    
    switch (filters.timeRange.toLowerCase()) {
      case 'past 24 hours':
        return (now - promptDate) <= 24 * 60 * 60 * 1000;
      case 'past week':
        return (now - promptDate) <= 7 * 24 * 60 * 60 * 1000;
      case 'past month':
        return (now - promptDate) <= 30 * 24 * 60 * 60 * 1000;
      case 'past year':
        return (now - promptDate) <= 365 * 24 * 60 * 60 * 1000;
      default:
        return true;
    }
  });

  if (loading) return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-red-500">Error: {error}</div>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header */}
      <div className="flex items-center gap-4 mb-8">
        <div className="w-16 h-16 flex items-center justify-center bg-green-500/10 rounded-xl border border-green-500/20">
          <span className="text-3xl filter drop-shadow-lg">{category?.icon}</span>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-white mb-2">{category?.name}</h1>
          <p className="text-gray-400">{category?.description}</p>
        </div>
      </div>

      {/* Search and Filters */}
      <div className="mb-8 space-y-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder={`Search ${category?.name} prompts...`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-gray-800 border border-gray-700 rounded-xl py-3 px-4 pl-11 text-gray-200 placeholder-gray-400 focus:outline-none focus:border-green-500"
              />
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-4 top-3.5" />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="relative">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center gap-2 bg-gray-800 border border-gray-700 rounded-xl py-3 px-4 text-gray-200 hover:bg-gray-700/50 transition-colors duration-200"
              >
                {showFilters ? <XMarkIcon className="h-5 w-5" /> : <FunnelIcon className="h-5 w-5" />}
                <span>Filters</span>
                <ChevronDownIcon className={`h-4 w-4 ml-2 transform transition-transform duration-200 ${showFilters ? 'rotate-180' : ''}`} />
              </button>
              
              {showFilters && (
                <div className="absolute right-0 mt-2 w-80 bg-gray-800 border border-gray-700 rounded-xl shadow-lg z-10">
                  <div className="p-4 space-y-4">
                    {/* Filter Header */}
                    <div className="flex items-center justify-between border-b border-gray-700 pb-2">
                      <div className="flex items-center gap-2">
                        <AdjustmentsHorizontalIcon className="h-5 w-5 text-green-400" />
                        <h3 className="font-medium text-green-400">Filter Options</h3>
                      </div>
                      <button
                        onClick={() => {
                          setFilters({
                            subcategory: 'all',
                            sortBy: 'newest',
                            timeRange: 'all',
                            difficulty: 'all',
                            length: 'all',
                            tags: []
                          });
                        }}
                        className="text-sm text-gray-400 hover:text-white transition-colors duration-200"
                      >
                        Reset All
                      </button>
                    </div>

                    {/* Subcategory Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Subcategory</label>
                      <select
                        value={filters.subcategory}
                        onChange={(e) => setFilters({...filters, subcategory: e.target.value})}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg py-2 px-3 text-gray-200 focus:border-green-500 focus:outline-none"
                      >
                        <option value="all">All Subcategories</option>
                        {category?.subcategories.map((sub) => (
                          <option key={sub} value={sub}>{sub}</option>
                        ))}
                      </select>
                    </div>

                    {/* Sort By Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Sort by</label>
                      <select
                        value={filters.sortBy}
                        onChange={(e) => setFilters({...filters, sortBy: e.target.value})}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg py-2 px-3 text-gray-200 focus:border-green-500 focus:outline-none"
                      >
                        {sortOptions.map((option) => (
                          <option key={option} value={option}>{option}</option>
                        ))}
                      </select>
                    </div>

                    {/* Time Range Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Time Range</label>
                      <select
                        value={filters.timeRange}
                        onChange={(e) => setFilters({...filters, timeRange: e.target.value})}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg py-2 px-3 text-gray-200 focus:border-green-500 focus:outline-none"
                      >
                        {timeRanges.map((range) => (
                          <option key={range} value={range.toLowerCase()}>{range}</option>
                        ))}
                      </select>
                    </div>

                    {/* Difficulty Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Difficulty Level</label>
                      <select
                        value={filters.difficulty}
                        onChange={(e) => setFilters({...filters, difficulty: e.target.value})}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg py-2 px-3 text-gray-200 focus:border-green-500 focus:outline-none"
                      >
                        {difficultyLevels.map((level) => (
                          <option key={level} value={level.toLowerCase()}>{level}</option>
                        ))}
                      </select>
                    </div>

                    {/* Length Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Prompt Length</label>
                      <select
                        value={filters.length}
                        onChange={(e) => setFilters({...filters, length: e.target.value})}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg py-2 px-3 text-gray-200 focus:border-green-500 focus:outline-none"
                      >
                        {lengthOptions.map((option) => (
                          <option key={option} value={option.toLowerCase()}>{option}</option>
                        ))}
                      </select>
                    </div>

                    {/* Tags Filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-200 mb-2">Tags</label>
                      <div className="flex flex-wrap gap-2">
                        {availableTags.map((tag) => (
                          <button
                            key={tag}
                            onClick={() => {
                              setFilters(prev => ({
                                ...prev,
                                tags: prev.tags.includes(tag)
                                  ? prev.tags.filter(t => t !== tag)
                                  : [...prev.tags, tag]
                              }));
                            }}
                            className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                              filters.tags.includes(tag)
                                ? 'bg-green-500/20 text-green-400 border border-green-500/40'
                                : 'bg-gray-700 text-gray-400 border border-gray-600 hover:border-green-500/40'
                            }`}
                          >
                            {tag}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Active Filters Display */}
        {(filters.subcategory !== 'all' || 
          filters.difficulty !== 'all' || 
          filters.length !== 'all' || 
          filters.tags.length > 0) && (
          <div className="flex flex-wrap items-center gap-2 pt-2">
            <span className="text-sm text-gray-400">Active Filters:</span>
            {filters.subcategory !== 'all' && (
              <button
                onClick={() => setFilters({...filters, subcategory: 'all'})}
                className="flex items-center gap-1 px-2 py-1 bg-green-500/10 text-green-400 rounded-lg text-sm"
              >
                Subcategory: {filters.subcategory}
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
            {filters.difficulty !== 'all' && (
              <button
                onClick={() => setFilters({...filters, difficulty: 'all'})}
                className="flex items-center gap-1 px-2 py-1 bg-green-500/10 text-green-400 rounded-lg text-sm"
              >
                Difficulty: {filters.difficulty}
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
            {filters.length !== 'all' && (
              <button
                onClick={() => setFilters({...filters, length: 'all'})}
                className="flex items-center gap-1 px-2 py-1 bg-green-500/10 text-green-400 rounded-lg text-sm"
              >
                Length: {filters.length}
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
            {filters.tags.map(tag => (
              <button
                key={tag}
                onClick={() => setFilters({
                  ...filters,
                  tags: filters.tags.filter(t => t !== tag)
                })}
                className="flex items-center gap-1 px-2 py-1 bg-green-500/10 text-green-400 rounded-lg text-sm"
              >
                {tag}
                <XMarkIcon className="h-4 w-4" />
              </button>
            ))}
            <button
              onClick={() => setFilters({
                ...filters,
                subcategory: 'all',
                difficulty: 'all',
                length: 'all',
                tags: []
              })}
              className="text-sm text-gray-400 hover:text-white transition-colors duration-200"
            >
              Clear All
            </button>
          </div>
        )}
      </div>

      {/* Prompts Grid */}
      <div className="grid grid-cols-1 gap-6">
        {timeFilteredPrompts.map((prompt) => (
          <PromptCard key={prompt.id} prompt={prompt} />
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;
