import React from 'react';
import { getCategoryById } from '../../utils/categories';

export const CategoryBadge = ({ categoryId, className = '' }) => {
  const category = getCategoryById(categoryId);
  
  if (!category) return null;

  // Map category IDs to tailwind color classes
  const colorMap = {
    'creative-writing': 'bg-pink-500/10 text-pink-400 border-pink-500/20',
    'code-generation': 'bg-blue-500/10 text-blue-400 border-blue-500/20',
    'image-prompts': 'bg-purple-500/10 text-purple-400 border-purple-500/20',
    'business': 'bg-amber-500/10 text-amber-400 border-amber-500/20',
    'tech': 'bg-cyan-500/10 text-cyan-400 border-cyan-500/20',
    'uncategorized': 'bg-gray-500/10 text-gray-400 border-gray-500/20'
  };
  
  const colors = colorMap[category.id] || colorMap['uncategorized'];
  
  return (
    <span className={`inline-flex items-center px-2 py-1 text-xs font-medium rounded-full border ${colors} ${className}`}>
      {category.label}
    </span>
  );
};
