import React from 'react';

function VideoPrompts() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Video Prompts</h1>
      <p>Welcome to the Video Prompts category page.</p>
    </div>
  );
}

export default VideoPrompts;
