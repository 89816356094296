export const formatDate = (dateString) => {
  try {
    // Handle Firestore Timestamp objects
    if (dateString && typeof dateString.toDate === 'function') {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(dateString.toDate());
    }
    
    // Handle ISO strings and other date formats
    if (dateString) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    }
    
    return 'No date';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};